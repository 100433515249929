import { IInputErrors } from "@/types/input";
import ICompany from "@/types/company";


export default class Company implements ICompany {
  id;
  name;
  created;
  students;

  constructor({
    id = undefined,
    name = "",
    created = "",
    students = [],
  }: Partial<ICompany> = {}) {
    this.id = id;
    this.name = name;
    this.created = created;
    this.students = students;
  }

  validate() {
    const errors = {} as IInputErrors;
    if (!this.name) errors["name"] = "Name should not be empty.";
    return errors;
  }
}
