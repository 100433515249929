<template>
  <Teleport to="body">
    <div
      v-if="store.modal || store.subModal"
      class="modal modal-backdrop fade show"
      aria-modal="true"
      role="dialog"
      tabindex="-1"
      @click.self="store.closeModals"
    >
      <div
        v-if="store.subModal"
        class="modal-backdrop fade show submodal"
        aria-modal="true"
        role="dialog"
        tabindex="-1"
        @click.self="store.closeSubModal"
      >
        <component
          :is="store.subModal.component"
          v-bind="store.subModal.props"
          @close="store.closeSubModal"
        />
      </div>

      <component
        :is="store.modal.component"
        v-if="store.modal"
        class="main-modal"
        v-bind="store.modal.props"
        @close="store.closeModal"
      />
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";
import { useModalStore } from "@/stores/modal.store";

const store = useModalStore();

// Make a function that will trigger on keydown
function keydownListener(event: KeyboardEvent) {
  // Assert the key is escape
  if (event.key === "Escape") {
    if (store.subModal) store.closeSubModal();
    else if (store.modal) store.closeModal();
  }
}

// Attach event listener on mount
onMounted(() => {
  document.addEventListener("keydown", keydownListener);
});

// Clean up on unmount
onUnmounted(() => {
  document.removeEventListener("keydown", keydownListener);
});
</script>

<style lang="scss" scoped>
.modal-backdrop {
  display: block;

  &:deep(.modal-body) .main-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-x: hidden;
  }
  &:deep(.modal-footer) {
    gap: 1em;
  }
}
.submodal {
  z-index: 2000;
}

.main-modal {
  z-index: 1000;
}
</style>
