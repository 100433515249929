<template>
  <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-sm-down">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <template v-if="isEdit">
            Edit Company
            <span class="text-secondary">{{ company.name }}</span>
          </template>
          <template v-else>
            Add Company
          </template>
        </h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="close"
        />
      </div>

      <div class="modal-body">
        <div class="container main-container g-0">
          <div class="row">
            <div class="col-12 col-sm-4">
              <label for="name" class="col-form-label"> Name </label>
              <input
                id="name"
                v-model="company.name"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': inputErrors['name'] }"
              >
              <div class="invalid-feedback">
                {{ inputErrors["name"] }}
              </div>
            </div>
            <div class="col-4 col-sm-4 d-flex flex-column">
              <label for="created" class="col-form-label">Created Date</label>
              <div class="d-flex align-items-center flex-grow-1">
                {{ formatDate(company.created) }}</div>
            </div>
            <div v-if="company.id" class="col-8 col-sm-4">
              <div class="d-flex justify-content-end flex-grow-0 align-items-center h-100">
                <button type="button" class="btn btn-outline-primary" @click="exportAttendances()">
                  <i class="bi bi-file-bar-graph-fill" /> Export Attendances
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="d-flex justify-content-between align-items-center mb-2">
                <h6 class="mb-0">
                  Students
                </h6>
                <button type="button" class="btn btn-outline-primary" @click="openSelectStudentsModal">
                  <i class="bi bi-plus-lg" /> Select Students
                </button>
              </div>
              <div v-if="company.students.length" class="attendance_table_wrapper">
                <SchoolClassStudentsTable
                  :student-ids="company.students"
                  @selected-students-changed="selectedStudentsChanged"
                />
              </div>
              <div v-else class="text-light-emphasis text-center py-2">
                Currently there are no students in this company.<br>
                Click the <strong>Select Students</strong> button to add them.
              </div>
            </div>
          </div>

          <div v-if="inputErrors['non_field_errors']" class="alert alert-danger p-1 mt-3 mb-0" role="alert">
            {{ inputErrors["non_field_errors"] }}
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div v-if="company?.id" class="me-auto">
          <button
            v-if="userStore.isCurrentTeacherAdmin"
            type="button"
            class="btn btn-danger"
          >
            Delete
          </button>
        </div>
        <button type="button" class="btn btn-secondary" @click="close">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" @click="save">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { api } from "@/api";
import { defineComponent, PropType, ref } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import useDateMixin from "@/mixins/date.mixin";
import Company from "@/types/models/company";
import { useUserStore } from "@/stores/user.store";
import { IInputErrors } from "@/types/input";
import SchoolClassStudentsTable from "@/components/modals/calendar/school_class/SchoolClassStudentsTable.vue";
import { useModalStore } from "@/stores/modal.store";
import setsMixin from "@/mixins/sets.mixin";
import { useStudentStore } from "@/stores/student.store";
import { useToastStore } from "@/stores/toast.store";


export default defineComponent({
  name: "CompanyModal",
  components: { SchoolClassStudentsTable },
  mixins: [mappingsMixin, setsMixin],
  props: {
    companyProp: {
      type: Object as PropType<Company>,
      default: () => {
        return null;
      },
    },
  },
  emits: ["close"],
  setup() {
    const { formatDate } = useDateMixin();
    const toastStore = useToastStore();
    const userStore = useUserStore();
    const studentStore = useStudentStore();
    const modalStore = useModalStore();
    const company = ref<Company>(new Company());
    const inputErrors = ref({} as IInputErrors);
    const loading = ref(false);

    return { company, formatDate, modalStore, toastStore, studentStore, userStore, loading, inputErrors };
  },
  computed: {
    isEdit() {
      return this.company?.id != null;
    },
  },
  created() {
    this.studentStore.resetState();
    this.studentStore.resetAttendanceStudents();
    if (this.companyProp?.students) {
      this.studentStore.getAttendanceStudents(this.companyProp?.students);
    }

    this.company = new Company({
      id: this.companyProp?.id,
      name: this.companyProp?.name,
      created: this.companyProp?.created,
      students: this.companyProp?.students,
    });
  },
  methods: {
    close() {
      this.$emit("close");
    },
    selectedStudentsChanged(newSelectedStudents: number[]) {
      const oldStudents = new Set(this.company.students);
      const newStudents = new Set(newSelectedStudents);
      this.company.students = newSelectedStudents;
      this.studentStore.attendanceStudents = this.studentStore.attendanceStudents.filter(student => newSelectedStudents.includes(student.id as number));

      console.log("selectedStudents changed: ", oldStudents, newSelectedStudents);
      if (this.setsAreEqual(oldStudents, newStudents) || this.isSetSubset(newStudents, oldStudents)) {
        // There is no need to refetch students if there was
        // no change or if some of the students were removed.
        return;
      }

      this.studentStore.resetState();
      this.studentStore.getAttendanceStudents(newSelectedStudents);
    },
    openSelectStudentsModal() {
      this.modalStore.openSelectStudentsSubModal(this.company.students, this.selectedStudentsChanged);
    },
    async exportAttendances() {
      try {
        const response = await api.userApi.exportCompanyAttendancesCSV(this.company);
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "student_attendances.csv");
        document.body.appendChild(link);
        link.click();
        link.remove(); // Clean up and remove the link when done
      } catch (error: any) {
        Object.keys(error).forEach(function(key) {
          error[key] = error[key].join("\n");
        });
        console.error(error);
        this.inputErrors = error;
        return;
      }
    },
    async save() {
      if (!this.company) return;

      try {
        if (this.company.id == null) {
          // Create new class event.
          await api.userApi.createCompany(this.company);
          // Show success message.
          this.toastStore.addToast("A new company was created successfully.");
        } else {
          // Update the existing class.
          await api.userApi.updateCompany(this.company);
          // Show success message.
          this.toastStore.addToast("Company was updated successfully.");
        }
      } catch (error: any) {
        console.error(error);
        Object.keys(error).forEach(function(key) {
          error[key] = error[key].join("\n");
        });
        this.inputErrors = error;
        return;
      }

      // Re-fetch students to update students table.
      this.userStore.refreshCompaniesCounter += 1;
      this.close();
    },
  },
});
</script>

<style scoped lang="scss"></style>
