import { IInputErrors } from "@/types/input";
import ISchoolClass from "@/types/school_class";


export default class SchoolClass implements ISchoolClass {
  id;
  title;
  teacher;
  level;
  class_language;
  class_location;
  class_type;
  weekday;
  time;
  is_active;
  is_individual;
  created;
  company;
  students;
  student_names;

  constructor({
    id = undefined,
    teacher = undefined,
    title = "",
    class_location = undefined,
    class_type = undefined,
    class_language = undefined,
    level = undefined,
    weekday = undefined,
    time = undefined,
    created = "",
    is_active = true,
    is_individual = false,
    company = undefined,
    students = [],
    student_names = [],
  }: Partial<ISchoolClass> = {}) {
    this.id = id;
    this.teacher = teacher;
    this.title = title;
    this.class_location = class_location
    this.class_type = class_type
    this.class_language = class_language;
    this.level = level;
    this.weekday = weekday;
    this.time = time;
    this.created = created;
    this.is_active = is_active;
    this.is_individual = is_individual;
    this.company = company;
    this.students = students;
    this.student_names = student_names;
  }

  validate() {
    const errors = {} as IInputErrors;
    if (!this.teacher) errors["teacher"] = "Class teacher should not be empty.";
    if (!this.level) errors["level"] = "Class language level should not be empty.";
    // if (!this.weekday) errors["weekday"] = "Class weekday should not be empty.";
    // if (!this.time) errors["time"] = "Class time should not be empty.";
    if (!this.class_language) errors["class_language"] = "Class language should not be empty.";
    return errors;
  }
}
