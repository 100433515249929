import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    setTimeOnDate(date: Date, hours: string, minutes: string) {
      const newDate = new Date(date);

      // Set the hours and minutes on the Date instance
      newDate.setUTCHours(parseInt(hours), parseInt(minutes));

      return newDate;
    },
  },
});
