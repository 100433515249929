<template>
  <LoadingSpinner v-if="loadingAttendanceStudents" class="my-3" text="Loading Students..." />
  <table v-else class="app_table table table-striped table-sm">
    <thead class="table-light">
      <tr>
        <th
          v-tooltip="'Attended'"
          class="th_checkbox attendance_checkbox"
          scope="col"
        >
          ATT
        </th>
        <th
          v-tooltip="'No Show'"
          class="th_checkbox attendance_checkbox"
          scope="col"
        >
          NS
        </th>
        <th class="th_recouped" scope="col">
          Recouped
        </th>
        <th scope="col" class="th_name">
          Name
        </th>
        <th scope="col" class="th_surname">
          Surname
        </th>
        <th scope="col">
          E-mail
        </th>
        <th scope="col">
          Language
        </th>
        <th scope="col">
          Level
        </th>
        <th scope="col">
          Pause Until
        </th>
        <th class="th_checkbox flexi_checkbox" scope="col">
          Flexi Classes
        </th>
        <th class="th_checkbox remove_item" scope="col" />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(attendance, index) in studentAttendances"
        :key="attendance.id"
        :class="{'tr_on_pause': isStudentOnPause(attendance)}"
      >
        <td class="td_checkbox attendance_checkbox">
          <input
            v-model="attendance.attended"
            :value="attendance.attended"
            class="form-check-input d-block"
            type="checkbox"
            @change="attendedChanged(attendance)"
          >
        </td>
        <td class="td_checkbox attendance_checkbox">
          <input
            v-model="attendance.no_show"
            :value="attendance.no_show"
            class="form-check-input d-block"
            type="checkbox"
            @change="noShowChanged(attendance)"
          >
        </td>
        <td class="td_recouped_event">
          <div
            v-tooltip="attendance.recouped_event ? getRecoupedAttendanceTitle(attendance) : 'Edit Recouped'"
            class="input-group input-group-sm"
            @click="openRecoupedAttendanceSubModal(attendance)"
          >
            <input
              type="text"
              class="form-control"
              :value="formatDate(attendance.recouped_event?.start)"
              readonly
            >
            <button class="btn btn-outline-primary btn-sm" type="button">
              <i class="bi bi-pencil-fill" />
            </button>
          </div>
        </td>
        <td class="td_name">
          {{ attendance.student.first_name }}
        </td>
        <td class="td_surname">
          {{ attendance.student.last_name }}
        </td>
        <td>{{ attendance.student.email }}</td>
        <td>{{ getMapping("class_language", attendance.student.class_language, "-") }}</td>
        <td>{{ getMapping("level", attendance.student.level, "-") }}</td>
        <td class="td_pause_until">
          <strong>{{ formatDate(attendance.student?.pause_until) }}</strong>
        </td>
        <td class="td_checkbox flexi_checkbox">
          <BooleanCheckCircle :is-true="attendance.student.flexible_classes_count > 0" />
        </td>
        <td class="td_checkbox remove_item hover-pointer" @click="removeStudentEventAttendance(index)">
          <i class="bi bi-trash3-fill text-danger" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, toRefs } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import IStudent from "@/types/student";
import IStudentEventAttendance, { IFullStudentEventAttendance } from "@/types/student_event_attendance";
import { useModalStore } from "@/stores/modal.store";
import useDateMixin from "@/mixins/date.mixin";
import { useStudentStore } from "@/stores/student.store";
import LoadingSpinner from "@/components/ui/spinner/LoadingSpinner.vue";
import { IStudentEventAttendanceExtended } from "@/types/student_class_purchase";
import setsMixin from "@/mixins/sets.mixin";
import BooleanCheckCircle from "@/components/ui/BooleanCheckCircle.vue";

export default defineComponent({
  name: "EventAttendanceTable",
  components: { BooleanCheckCircle, LoadingSpinner },
  mixins: [mappingsMixin, setsMixin],
  props: {
    eventStart: {
      type: String,
      default: () => "",
    },
    attendances: {
      type: Array as () => IStudentEventAttendance[],
      default: () => [],
    },
  },
  emits: ["attendances-changed"],
  setup() {
    const modalStore = useModalStore();
    const studentStore = useStudentStore();
    const { attendanceStudents } = toRefs(studentStore);
    const { attendanceStudents: loadingAttendanceStudents } = toRefs(studentStore.loading);

    const { formatDate, formatTime } = useDateMixin();
    return { modalStore, studentStore, formatDate, formatTime, attendanceStudents, loadingAttendanceStudents };
  },
  data() {
    return {
      studentAttendances: [] as IFullStudentEventAttendance[],
      searchQuery: "",
    };
  },
  computed: {
    attendanceStudentIds(): number[] {
      return this.attendances.map((attendance) => attendance.student);
    },
  },
  watch: {
    async attendances(
      newAttendances: IStudentEventAttendanceExtended[],
      oldAttendances: IStudentEventAttendanceExtended[],
    ) {
      // Fetch students, only if the students have changed.
      // Don't refetch only if some attendance property changed, like attendance.no_show.
      const oldStudents = new Set(oldAttendances.map((attendance) => attendance.student));
      const newStudents = new Set(newAttendances.map((attendance) => attendance.student));

      if (this.setsAreEqual(oldStudents, newStudents) || this.isSetSubset(newStudents, oldStudents)) {
        // Sets of old and new students are equal, it means it has not changed.
        // Or newStudents are a subset of the old students, which means some of them were removed.
        console.log("sets equal or subset of attendance students")
        console.log(newStudents);
        return;
      }
      console.log("get attenddance students")

      await this.studentStore.getAttendanceStudents(this.attendanceStudentIds);
      await this.updateStudentAttendances();
    },
    attendanceStudents: {
      handler: "updateStudentAttendances",
    },
  },
  created() {
    this.studentStore.getAttendanceStudents(this.attendanceStudentIds);
  },
  methods: {
    isStudentOnPause(attendance: any) {
      if (this.eventStart) {
        const eventStartDate = new Date(this.eventStart);
        const pauseUntilDate = new Date(attendance.student?.pause_until);
        return pauseUntilDate >= eventStartDate;
      }
      return !!attendance.student?.pause_until;
    },
    attendedChanged(attendance: any) {
      // Make attended and no show checkboxes behave like radio buttons.
      // Only one can be active.
      if (attendance.attended) {
        attendance.no_show = false;
      }
      this.emitAttendanceChanged();
    },
    noShowChanged(attendance: any) {
      // Make attended and no show checkboxes behave like radio buttons.
      // Only one can be active.
      if (attendance.no_show) {
        attendance.attended = false;
      }
      this.emitAttendanceChanged();
    },
    openRecoupedAttendanceSubModal(attendance: IFullStudentEventAttendance) {
      this.modalStore.openRecoupedAttendanceSubModal(attendance, this.selectedRecoupedAttendanceChanged);
    },
    getRecoupedAttendanceTitle(attendance: IFullStudentEventAttendance) {
      if (!attendance?.recouped_event) return "";
      const e = attendance.recouped_event;

      return `${this.formatDate(e.start, true)}
      ${e.title}
      ${this.formatTime(e.start)} - ${this.formatTime(e.end)}`;
    },
    selectedRecoupedAttendanceChanged(attendance: any, recoupedAttendance: number, recoupedEvent: any) {
      // Values must be null if they are not set, so that they are
      // included in the axios payload.
      attendance.recouped_attendance = recoupedAttendance ?? null;
      attendance.recouped_event = recoupedEvent ?? null;

      // Automatically select attended if he recouped.
      if (recoupedAttendance) {
        attendance.attended = true;
        attendance.no_show = false;
      }
      this.emitAttendanceChanged();
    },
    async updateStudentAttendances() {
      // Attendance students are not yet loaded, wait for them to be loaded.
      if (!this.attendanceStudents.length) return;

      // Update studentAttendances when the attendances prop changes.
      this.studentAttendances = this.attendances.map((attendance) => {
        return {
          ...attendance,
          student: this.getMapping("student", attendance.student) as IStudent,
        };
      });
    },
    emitAttendanceChanged() {
      // Emit updated attendance, but replace the extended student with his ID to return
      // the attendances in the original form of IStudentEventAttendance array.
      this.$emit(
        "attendances-changed",
        this.studentAttendances.map((attendance) => {
          return {
            ...JSON.parse(JSON.stringify(attendance)),
            student: attendance.student.id,
          };
        }) as IStudentEventAttendance[],
      );
    },
    removeStudentEventAttendance(index: number) {
      this.studentAttendances.splice(index, 1);
      this.emitAttendanceChanged();
    },
    studentSort(a: IStudent, b: IStudent) {
      // Sort by title descending.
      return a.title.localeCompare(b.title);
    },
  },
});
</script>

<style scoped lang="scss">
.th_recouped {
  text-align: right;
}
.td_surname,
.th_surname {
  width: 12rem;
  min-width: 12rem;
}
.td_name,
.th_name {
  width: 6rem;
  min-width: 6rem;
}
.th_recouped,
.td_recouped_event {
  width: 9rem;
  min-width: 9rem;
  input {
    padding-right: 0.325rem;
    padding-left: 0.325rem;
    text-align: center;
    text-overflow: ellipsis;

    &:hover {
      cursor: pointer;
    }
  }
}
.th_checkbox,
.td_checkbox {
  &.flexi_checkbox {
    width: 60px;
  }
  &.attendance_checkbox {
    width: 40px;

    .form-check-input {
      width: 1.2em;
      height: 1.2em;
      margin: auto;
    }
  }
  &.remove_item {
    width: 50px;
    font-size: 1.1rem;
  }
}
.tr_on_pause td {
  background: #d5bec5;
}
</style>
