<template>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Delete Student
        </h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="close"
        />
      </div>

      <div class="modal-body">
        <div class="container g-0">
          <div class="row">
            <div class="col">
              Are you sure you want to delete student <strong>{{ student.title }}</strong>?<br>
              This will also delete all his payment history and class attendance history.
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer justify-content-between">
        <button
          type="button"
          class="btn btn-danger"
          @click="confirmDelete"
        >
          Delete
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="close"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Student from "@/types/models/student";


export default defineComponent({
  name: "DeleteStudentModal",
  props: {
    student: {
      type: Object as PropType<Student>,
      default: () => {
        return null;
      },
    },
    confirmDeleteCallback: {
      type: Function,
      default: () => {
        return null;
      },
    },
  },
  emits: ["close", "deleteConfirmed"],
  methods: {
    confirmDelete() {
      this.close();
      this.confirmDeleteCallback();
    },
    close() {
      this.$emit("close");
    },
  },
});
</script>
<style scoped lang="scss">
.modal-dialog {
  width: 550px;
}
</style>
