<template>
  <ul class="nav nav-tabs" :class="{'nav-fill': fill}">
    <li v-for="tab in tabs" :key="tab.key" class="nav-item">
      <a
        v-tooltip="{content: tab.tooltip, disabled: !tab.tooltip}"
        class="nav-link"
        :class="{ active: selectedTab == tab.key }"
        aria-current="page"
        href="#"
        @click="selectedTab = tab.key"
      >
        {{ tab.title }}
      </a>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from "vue"

interface ITab {
  key: string;
  title: string;
  tooltip?: string;
}

export default defineComponent({
  name: "NavTabs",
  props: {
    tabs: {
      type: Array as () => ITab[],
      default: () => [],
    },
    fill: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  computed: {
    selectedTab: {
      get() {
        return this.modelValue ? this.modelValue : "";
      },
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
  },
})
</script>
<style scoped lang="scss">

</style>
