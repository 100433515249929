import { IInputErrors } from "@/types/input";
import IStudentClassPurchase from "@/types/student_class_purchase";

export default class StudentClassPurchase implements IStudentClassPurchase {
  id;
  student;
  number_of_classes;
  number_of_group_classes;
  number_of_individual_classes;
  is_flexible;
  is_initial_class;
  reference_number;
  invoice_issued_date;
  paid_date;
  paid_amount;
  notes;
  created;
  is_open;
  attendances;
  used_classes_count;
  used_group_classes_count;
  used_individual_classes_count;
  unused_classes_count;
  unused_group_classes_count;
  unused_individual_classes_count;

  constructor({
    id = undefined,
    student,
    number_of_classes = 4,
    number_of_group_classes = 4,
    number_of_individual_classes = 0,
    used_classes_count = 0,
    used_group_classes_count = 0,
    used_individual_classes_count = 0,
    unused_classes_count = 4,
    unused_group_classes_count = 4,
    unused_individual_classes_count = 0,
    is_flexible = false,
    is_initial_class = false,
    reference_number = "",
    paid_date = undefined,
    invoice_issued_date = undefined,
    paid_amount = 0,
    notes = "",
    created = "",
    is_open = false,
    attendances = [],
  }: Partial<IStudentClassPurchase> = {}) {
    this.id = id;
    this.student = student as number;
    this.number_of_classes = number_of_classes;
    this.number_of_group_classes = number_of_group_classes;
    this.number_of_individual_classes = number_of_individual_classes;
    this.used_classes_count = used_classes_count;
    this.used_group_classes_count = used_group_classes_count;
    this.used_individual_classes_count = used_individual_classes_count;
    this.unused_classes_count = unused_classes_count;
    this.unused_group_classes_count = unused_group_classes_count;
    this.unused_individual_classes_count = unused_individual_classes_count;
    this.is_flexible = is_flexible
    this.is_initial_class = is_initial_class
    this.reference_number = reference_number;
    this.paid_date = paid_date;
    this.invoice_issued_date = invoice_issued_date;
    this.paid_amount = paid_amount;
    this.notes = notes;
    this.created = created;
    this.is_open = is_open;
    this.attendances = attendances;
  }

  validate() {
    const errors = {} as IInputErrors;
    if (!this.student) errors["student"] = "Student should not be empty.";
    if (!this.number_of_classes || this.number_of_classes <= 0) {
      errors["number_of_classes"] = "Number of classes must be positive.";
    } else if (
      (!this.number_of_group_classes || this.number_of_group_classes <= 0) &&
      (!this.number_of_individual_classes || this.number_of_individual_classes <= 0)
    ) {
      errors["number_of_classes"] = "Class purchase must have at least one individual or group class.";
    }
    return errors;
  }
}
