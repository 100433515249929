<template>
  <div ref="ddwr" class="dd-wr form-control">
    <div
      class="result hover"
      :class="{ 'can-be-cleared': showClearButton && selection, disable: disabled }"
      @click="toggle"
    >
      <div :class="['select-val', { grey: !modelValue }]">
        {{ getName }}
      </div>
      <div class="buttons">
        <i v-if="showClearButton" class="clear_button bi bi-x" @click.stop="clearValue" />

        <i v-if="isOpen" class="bi bi-chevron-up" />
        <i v-else class="bi bi-chevron-down" />
      </div>
    </div>
    <transition ref="transition" name="dd" mode="out-in">
      <div v-if="isOpen" class="dd-box">
        <input
          v-if="!disableSearch"
          ref="searchInput"
          v-model="searchQuery"
          type="text"
          class="form-control focus-ring-none"
          placeholder="Search..."
          @keydown.down="onArrowDownKeydown"
          @keydown.up="onArrowUpKeydown"
          @keydown.enter="onEnterKeydown"
          @keydown.esc.stop="toggle"
        >
        <div
          v-for="(item, idx) in filteredOptions"
          :key="idx"
          class="item"
          :class="{ active: modelValue === item.id, highlighted: idx === highlightedIndex }"
        >
          <input
            :id="'check' + item.id"
            v-model="selection"
            class="form-control"
            type="radio"
            :value="item.id"
            hidden
          >
          <label class="form-control" :for="'check' + item.id"> {{ item.name }}</label>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import ISelectInputChoice from "@/types/input";
import { defineComponent, Ref, ref } from "vue";

/**
 * In the future refactor to be more like VueSelect
 * https://vue-select.org/
 *
 * As it allows to start typing right away, instead of clicking into search.
 **/
export default defineComponent({
  props: {
    options: {
      type: Array<ISelectInputChoice>,
      default() {
        return [];
      },
    },
    modelValue: {
      type: [String, Number],
      default: undefined,
    },
    queryFieldName: {
      type: String,
      default: "name",
    },
    placeholder: {
      type: String,
      default: "",
    },
    nullable: {
      type: Boolean,
      default: false,
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
    showClearButton: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const searchQuery = ref("");
    const isOpen = ref(false);
    const highlightedIndex = ref(-1);
    const searchInput: Ref<HTMLInputElement | null> = ref(null);

    return { searchQuery, isOpen, highlightedIndex, searchInput };
  },
  computed: {
    getName() {
      return this.optionNamesMap[this.selection] || this.placeholder || "N/A";
    },
    selection: {
      get() {
        return this.modelValue ?? this.placeholder;
      },
      set(val: string) {
        this.isOpen = false;
        this.$emit("update:modelValue", val);
      },
    },
    optionNamesMap() {
      return this.options.reduce((acc: Record<any, any>, obj: ISelectInputChoice) => {
        acc[obj.id] = obj.name;
        return acc;
      }, {});
    },
    filteredOptions() {
      return this.options.filter(
        (option: ISelectInputChoice) => this.simpleSearchMatch(this.searchQuery, option[this.queryFieldName]),
      )
    },
  },
  mounted() {
    window.addEventListener("click", this.close);
  },
  methods: {
    simpleSearchMatch(query: string, target: string): boolean {
      const normalize = (str: string): string => str.toLowerCase().replace(/[^a-z0-9]+/g, " ").trim();
      const queryParts: string[] = normalize(query).split(/\s+/);
      const normalizedTarget: string = normalize(target);
      return queryParts.every((part: string) => normalizedTarget.includes(part));
    },
    clearValue() {
      this.selection = "";
    },
    close(e: any) {
      const el = this.$refs.ddwr as HTMLDivElement;
      if (el !== e.target && !el?.contains(e.target)) this.isOpen = false;
    },
    onArrowDownKeydown() {
      if (this.highlightedIndex + 1 === this.filteredOptions.length) {
        return;
      }
      this.highlightedIndex += 1;
    },
    onArrowUpKeydown() {
      if (this.highlightedIndex === -1) {
        return;
      }
      this.highlightedIndex -= 1;
    },
    onEnterKeydown() {
      if (this.highlightedIndex > -1 && this.highlightedIndex < this.filteredOptions.length) {
        this.selection = this.filteredOptions[this.highlightedIndex].id;
        this.highlightedIndex = -1;
      }
    },
    focusSearchInput() {
      // Set focus to search input.
      this.$nextTick(() => {
        if (this.$refs.searchInput) {
          this.highlightedIndex = -1;
          this.searchInput?.focus();
        }
      });
    },
    toggle() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.focusSearchInput();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
select.form-select.is-placeholder {
  color: gray;

  option {
    color: initial;
  }
}


$input-height: 0.5rem;
.dd-wr {
  position: relative;

  .buttons {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0.25rem;

    .clear_button::before {
      font-size: 1.15rem;
    }

    i {
      line-height: 1;
    }
  }

  input {
    position: sticky;
    top: 0;
    border: unset;
    width: 100%;
    border-radius: 0;
    padding: 0.5rem;
    border-bottom: 1px solid var(--bs-border-color);
    &:hover {
      box-shadow: unset;
    }
  }
}

.result {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .grey {
    opacity: 0.5;
  }
}

.dd-box {
  position: absolute;
  top: 2.25rem;
  left: 0;
  width: 100%;
  border-radius: 0.25rem;
  background-color: white;
  border: 1px solid var(--bs-border-color);
  max-height: 250px;
  overflow-y: auto;
  z-index: 5;
  box-shadow: 0 6px 22px -6px rgb(0 0 0 / 10%);

  .item {
    label {
      cursor: pointer;
      border: none;
    }
    &.highlighted label,
    label:hover {
      background-color: rgba(170, 179, 189, 0.2);
    }
    &.active label {
      color: var(--bs-primary);
    }
  }
}

.select-val {
  margin-right: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disable {
  opacity: 0.4;
  pointer-events: none;
}
</style>
