<template>
  <ul class="select-event nav nav-pills nav-justified">
    <li v-for="eventType in eventTypes" :key="eventType.key" class="nav-item">
      <a
        class="nav-link"
        :class="[`bg_${eventType.key}`, selectedEventType == eventType.key ? '' : `bg_${eventType.key}--light`,]"
        aria-current="page"
        href="#"
        @click="selectedEventType = eventType.key"
      >
        {{ eventType.title }}
      </a>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "SelectEventType",
  props: {
    modelValue: {
      type: String,
      default: "class",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      eventTypes: [
        {
          key: "not_available",
          title: "Not Available",
        },
        {
          key: "exception",
          title: "Exception",
        },
        {
          key: "admin_work",
          title: "Admin Work",
        },
      ],
    }
  },
  computed: {
    selectedEventType: {
      get() {
        return this.modelValue ? this.modelValue : "class";
      },
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
  },
})
</script>

<style scoped lang="scss">
/* Calendar Event Colors */
ul.select-event {
  a {
    font-weight: 500 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>
