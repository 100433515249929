
import IClassCalendarEvent from "@/types/calendar_class_event";
import CalendarEvent from "@/types/models/calendar_event";
import { IInputErrors } from "@/types/input";
import { EventType } from "@/types/enums/event_type";

export default class CalendarClassEvent extends CalendarEvent implements IClassCalendarEvent {
  school_class;
  title = "";
  is_class_time_different;
  is_class_teacher_different;
  attendances;

  constructor({
    id = undefined,
    teacher = undefined,
    school_class = undefined,
    is_class_time_different = false,
    is_class_teacher_different = false,
    title = "",
    start = "",
    end = "",
    notes = "",
    attendances = [],
    event_type = EventType.CLASS,
  }: Partial<IClassCalendarEvent> = {}) {
    super({
      id,
      teacher,
      title,
      start,
      end,
      notes,
      event_type,
    });
    this.school_class = school_class;
    this.is_class_time_different = is_class_time_different;
    this.is_class_teacher_different = is_class_teacher_different;
    this.attendances = attendances;
  }

  validate() {
    const errors = super.validate() as IInputErrors;

    if (this.event_type === EventType.OUR_LANGUAGE_HOUR) {
      if (!this.attendances.length) errors["attendances"] = "Select a student for this Our Language Hour."
    } else {
      if (!this.school_class) errors["school_class"] = "Class should not be empty."
    }
    return errors;
  }
  validate_school_class() {
    if (this.event_type !== EventType.OUR_LANGUAGE_HOUR) {
      if (!this.school_class) return "Class should not be empty."
    }

    return "";
  }
}
