<template>
  <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-sm-down">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <template v-if="isEdit">
            Edit Teacher
            <span class="text-secondary">{{ teacherFullName }}</span>
          </template>
          <template v-else>
            Add Teacher
          </template>
        </h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="close"
        />
      </div>

      <div class="modal-body">
        <NavTabs v-model="activeTab" :tabs="tabs" />
        <FullScreenLoadingSpinner v-if="loading" />
        <TeacherPersonalInfo v-if="activeTab == 'personalInfo'" v-model="teacher" :input-errors="inputErrors" />
        <TeacherEvents v-if="activeTab == 'events'" v-model="teacher" />
      </div>
      <div class="modal-footer">
        <!--        <div v-if="teacher?.id" class="me-auto">-->
        <!--          <button type="button" class="btn btn-danger" @click="confirmDelete">-->
        <!--            Delete-->
        <!--          </button>-->
        <!--        </div>-->
        <button type="button" class="btn btn-secondary" @click="close">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="loading"
          @click="save"
        >
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import { IInputErrors } from "@/types/input";
import { useUserStore } from "@/stores/user.store";
import { useToastStore } from "@/stores/toast.store";
import { useModalStore } from "@/stores/modal.store";
import User from "@/types/models/user";
import TeacherPersonalInfo from "@/components/modals/teacher/tabs/TeacherPersonalInfo.vue";
import NavTabs from "@/components/ui/nav_tabs/NavTabs.vue";
import TeacherEvents from "@/components/modals/teacher/tabs/TeacherEvents.vue";
import FullScreenLoadingSpinner from "@/components/ui/spinner/FullScreenLoadingSpinner.vue";
import LoadingSpinner from "@/components/ui/spinner/LoadingSpinner.vue";

export default defineComponent({
  name: "TeacherModal",
  components: { LoadingSpinner, FullScreenLoadingSpinner, TeacherEvents, NavTabs, TeacherPersonalInfo },
  mixins: [mappingsMixin],
  props: {
    teacherProp: {
      type: Object as PropType<User>,
      default: () => {
        return null;
      },
    },
  },
  emits: ["close"],
  setup() {
    const userStore = useUserStore();
    const toastStore = useToastStore();
    const modalStore = useModalStore();
    const inputErrors = ref({} as IInputErrors);
    const teacher = ref<User>();
    const activeTab = ref("personalInfo");
    const loading = ref(false);
    const tabs = [
      {
        key: "personalInfo",
        title: "Personal Info",
      },
      {
        key: "events",
        title: "Events",
      },
    ];

    return { userStore, modalStore, toastStore, inputErrors, teacher, tabs, loading, activeTab };
  },
  computed: {
    isEdit() {
      return this.teacher?.id != null;
    },
    teacherFullName() {
      const fullName = [this.teacher?.first_name, this.teacher?.last_name].join(" ");
      if (fullName) {
        return `(${fullName})`;
      }

      return "";
    },
  },
  mounted() {
    this.teacher = new User({
      id: this.teacherProp?.id,
      first_name: this.teacherProp?.first_name,
      last_name: this.teacherProp?.last_name,
      email: this.teacherProp?.email,
      is_active: this.teacherProp?.is_active,
      is_staff: this.teacherProp?.is_staff,
    });
  },
  methods: {
    async save() {
      if (!this.teacher) return;
      this.loading = true;

      try {
        await this.userStore.saveTeacher(this.teacher);
      } catch (error: any) {
        this.inputErrors = error;
        this.loading = false;
        return;
      }

      // Re-fetch teachers to update teacher table.
      await this.userStore.getTeachers();
      await this.userStore.getCurrentTeacherInfo();
      this.loading = false;
      this.close();
    },
    confirmDelete() {
      if (!this.teacher) {
        return;
      }
      // this.modalStore.openDeleteStudentModal(this.teacher, this.deleteTeacher);
    },
    async deleteTeacher() {
      if (!this.teacher?.id) return;

      try {
        // await this.userStore.deleteTeacher(this.teacher.id);
      } catch (error: any) {
        this.inputErrors = error;
        console.error(error);
        return;
      }

      // Re-fetch teachers to update teachers table.
      await this.userStore.getTeachers();
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
});
</script>
<style scoped lang="scss">
.modal-body {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.modal-content {
  min-height: 60vh;
}

@include sm {
  .modal-content {
    max-height: 90vh;
  }
  .modal-dialog.modal-xl {
    --bs-modal-width: 100%;
  }
}
@include md {
  .modal-dialog.modal-xl {
    --bs-modal-width: 940px;
  }
}
</style>
