import { defineStore } from "pinia";
import { api } from "@/api";
import { useMappingsStore } from "@/stores/mappings.store";
import { useToastStore } from "@/stores/toast.store";
import { ITeacherCalendarResource } from "@/types/teacher";
import User from "@/types/models/user";

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    // The authenticated user/teacher.
    currentTeacher: {} as ITeacherCalendarResource,
    refreshCompaniesCounter: 0,
    loading: {
      loggedTeacherInfo: false,
      teachers: false,
      companies: false,
    },
  }),
  getters: {
    mappingsStore() {
      return useMappingsStore();
    },
    toastStore() {
      return useToastStore();
    },
    isCurrentTeacherAdmin(): boolean {
      return this.currentTeacher?.is_staff ?? false;
    },
  },
  actions: {
    async getCurrentTeacherInfo() : Promise<void> {
      this.loading.loggedTeacherInfo = true;
      return api.userApi.getCurrentTeacherInfo()
        .then(teacher => {
          this.currentTeacher = teacher;
        })
        .catch((error: Error) => console.error(error))
        .finally(() => this.loading.loggedTeacherInfo = false);
    },
    async getTeachers() : Promise<void> {
      this.loading.teachers = true;
      return api.userApi.getTeachers()
        .then(teachers => {
          this.mappingsStore.addMapping(teachers, "teacher");
          this.mappingsStore.addChoices(teachers, "teacher","title");
        })
        .catch((error: Error) => console.error(error))
        .finally(() => this.loading.teachers = false);
    },
    async getAllCompanies() : Promise<void> {
      this.loading.companies = true;
      return api.userApi.getAllCompanies()
        .then(companies => {
          this.mappingsStore.addMapping(companies, "company");
          this.mappingsStore.addChoices(companies, "company", "name");
        })
        .catch((error: Error) => console.error(error))
        .finally(() => this.loading.companies = false);
    },
    async saveTeacher(teacher: User) {
      const inputErrors = teacher.validate();

      if (Object.keys(inputErrors).length > 0) return Promise.reject(inputErrors);

      let response;
      try {
        if (teacher.id == null) {
          // Create new class event.
          response = await api.userApi.createTeacher(teacher);
          this.toastStore.addToast("A new teacher was added successfully.");
        } else {
          // Update the existing student.
          response = await api.userApi.updateTeacher(teacher);
          this.toastStore.addToast("Teacher was updated successfully.");
        }
      } catch (error: any) {
        console.error(error);
        Object.keys(error).forEach(function(key) {
          error[key] = error[key].join("\n");
        });
        return Promise.reject(error);
      }

      return response?.data;
    },
  },
});
