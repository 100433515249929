import { axiosClient } from "@/api";
import { responseData } from "@/api/utils";
import IClassCalendarEvent from "@/types/calendar_class_event";
import ISchoolClass from "@/types/school_class";
import SchoolClass from "@/types/models/school_class";
import CalendarClassEvent from "@/types/models/calendar_class_event";
import ICalendarEvent from "@/types/calendar_event";
import IPagination from "@/types/pagination";

class CalendarApi {
  /* School Classes */
  getAllSchoolClasses(): Promise<any> {
    return responseData(axiosClient.get<ISchoolClass[]>("/class/all/"));
  }
  getSchoolClasses(
    pagination?: IPagination,
    searchQuery?: string,
    filter?: Record<string, any>,
  ): Promise<any> {
    return responseData(
      axiosClient.get("/class/", {
        params: {
          ...pagination,
          ...filter,
          q: searchQuery,
        },
      }),
    );
  }
  createSchoolClass(schoolClass: SchoolClass): Promise<any> {
    return responseData(axiosClient.post(
      "/class/", schoolClass,
    ));
  }
  updateSchoolClass(schoolClass: SchoolClass): Promise<any> {
    return responseData(axiosClient.patch(
      `/class/${schoolClass.id}/`, schoolClass,
    ));
  }
  getSchoolClassEvents(schoolClass: SchoolClass): Promise<any> {
    return responseData(axiosClient.get(
      `/class/${schoolClass.id}/event/`,
    ));
  }
  deleteSchoolClass(schoolClass: SchoolClass): Promise<any> {
    return responseData(axiosClient.delete(
      `/class/${schoolClass.id}/`,
    ));
  }

  /* Class Events */
  getEvents(start: string, end: string): Promise<any> {
    return responseData(
      axiosClient.get("/event/", {
        params: {
          start: start,
          end: end,
        },
      }),
    ).then(({ data }) => data.map(parseEvent));
  }
  createClassEvent(classEvent: CalendarClassEvent): Promise<any> {
    return responseData(axiosClient.post(
      `/class/${classEvent.school_class}/event/`, classEvent),
    );
  }
  updateClassEvent(classEvent: CalendarClassEvent): Promise<any> {
    return responseData(axiosClient.patch(`/class/event/${classEvent.id}/`, classEvent));
  }
  deleteEvent(eventId?: number): Promise<any> {
    return responseData(axiosClient.delete(`/event/${eventId}/`));
  }
  createBackgroundEvent(event: ICalendarEvent): Promise<any> {
    return responseData(axiosClient.post(
      "/event/", event),
    );
  }
  updateBackgroundEvent(event: ICalendarEvent): Promise<any> {
    return responseData(axiosClient.patch(`/event/${event.id}/`, event));
  }
}

function parseEvent(event: IClassCalendarEvent | ICalendarEvent) {
  // const isBackgroundEvent = BACKGROUND_EVENT_TYPES.includes(event.event_type)
  return {
    ...event,
    resourceId: event.teacher,
    // Set display properties based on the event type.
    color: `var(--color-${event.event_type.replace(/_/g, "-")})`,
    className: `bg_event bg_${event.event_type}`,
    // display: isBackgroundEvent ? "background" : "auto",
  };
}

export const calendarApi = new CalendarApi();
