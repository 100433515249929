import { axiosClient } from "@/api";
import { responseData } from "@/api/utils";
import ITeacher, { ITeacherCalendarResource } from "@/types/teacher";
import Student from "@/types/models/student";
import IStudentClassPurchase, { IStudentEventAttendanceExtended } from "@/types/student_class_purchase";
import IStudentEventAttendance, { IFullStudentEventAttendance } from "@/types/student_event_attendance";
import User from "@/types/models/user";
import ICalendarEvent from "@/types/calendar_event";
import IPagination from "@/types/pagination";
import IStudent from "@/types/student";
import ICompany from "@/types/company";
import Company from "@/types/models/company";
import IStudentRegistration from "@/types/student_registration";


class UserApi {
  /* Students */
  getStudents(
    pagination?: IPagination,
    searchQuery?: string,
    filter?: Record<string, any>,
    sort?: string[],
    hideInitialClassPassed?: boolean,
    selectedStudentId?: number,
  ): Promise<any> {
    return responseData(
      axiosClient.get("/student/", {
        params: {
          ...pagination,
          ...filter,
          sort: sort?.join(",") ?? [],
          hide_initial_class_passed: hideInitialClassPassed,
          selected_student_id: selectedStudentId,
          q: searchQuery,
        },
      }),
    );
  }
  getStudentsByIds(
    studentIds: number[],
  ): Promise<any> {
    return responseData(
      axiosClient.post("/student/list_by_ids/", {
        student_ids: studentIds,
      }),
    ).then(({ data }) :IStudent[] => data);
  }
  createStudent(student: Student): Promise<any> {
    return responseData(
      axiosClient.post("/student/", student),
    );
  }
  updateStudent(student: Student): Promise<any> {
    return responseData(
      axiosClient.patch(`/student/${student.id}/`, student),
    );
  }
  deleteStudent(studentId: number): Promise<any> {
    return responseData(
      axiosClient.delete(`/student/${studentId}/`),
    );
  }
  getStudentClasses(studentId: number): Promise<any> {
    return responseData(
      axiosClient.get(`/student/${studentId}/class/`),
    ).then(({ data }) => data);
  }
  getStudentClassPurchases(studentId: number): Promise<any> {
    return responseData(
      axiosClient.get(`/student/${studentId}/class_purchase/`),
    ).then(({ data }) : IStudentClassPurchase[] => data.map(parseStudentClassPurchase));
  }
  getStudentAttendances(studentId: number): Promise<any> {
    return responseData(
      axiosClient.get(`/student/${studentId}/attendances/`),
    ).then(({ data }) : IStudentEventAttendanceExtended[] => data);
  }
  getStudentAttendancesToRecoup(attendance: IFullStudentEventAttendance): Promise<any> {
    return responseData(
      axiosClient.get(
        `/student/${attendance.student.id}/attendances_to_recoup/`, {
          params: {
            attendance_id: attendance.id,
          },
        },
      ),
    ).then(({ data }) : IStudentEventAttendance[] => data);
  }

  /** Student Registrations **/
  getStudentRegistrations(
    pagination?: IPagination,
    searchQuery?: string,
    filterLinkedStudent?: boolean,
  ): Promise<any> {
    return responseData(
      axiosClient.get("/student-registration/", {
        params: {
          ...pagination,
          has_linked_student: filterLinkedStudent,
          q: searchQuery,
        },
      }),
    );
  }
  getStudentsToBeLinked(studentId?: number): Promise<any> {
    return responseData(
      axiosClient.get("/student/to-be-linked/", {
        params: {
          student_id: studentId,
        },
      }),
    ).then(({ data }) : IStudent => data);
  }
  exportAttendancesCSV(): Promise<any> {
    return responseData(
      axiosClient.get("/student/attendances/csv/", {
        responseType: "blob",
      }),
    );
  };
  exportBillingCSV(): Promise<any> {
    return responseData(
      axiosClient.get("/student/billing/csv/", {
        responseType: "blob",
      }),
    );
  };
  exportNeedNewClassPurchaseCSV(): Promise<any> {
    return responseData(
      axiosClient.get("/student/need_new_class_purchase/csv/", {
        responseType: "blob",
      }),
    );
  };
  getStudentRegistration(studentRegistrationId: number): Promise<any> {
    return responseData(
      axiosClient.get(`/student-registration/${studentRegistrationId}/`),
    ).then(({ data }) : IStudentRegistration => data);
  };
  updateStudentRegistration(studentRegistrationId: number, studentToLink?: number): Promise<any> {
    return responseData(
      axiosClient.patch(`/student-registration/${studentRegistrationId}/`, {
        student_to_link:studentToLink,
      }),
    );
  }
  deleteStudentRegistration(studentRegistrationId: number): Promise<any> {
    return responseData(
      axiosClient.delete(`/student-registration/${studentRegistrationId}/`),
    );
  }
  createStudentFromRegistration(studentRegistrationId: number): Promise<any> {
    return responseData(
      axiosClient.post(`/student-registration/${studentRegistrationId}/create-student/`),
    );
  }

  /* Teachers */
  getCurrentTeacherInfo(): Promise<any> {
    return responseData(
      axiosClient.get("/teacher/info/"),
    ).then(({ data }) : ITeacher => parseTeacher(data));
  }
  getTeachers(): Promise<any> {
    return responseData(
      axiosClient.get("/teacher/"),
    ).then(({ data }) : Array<ITeacherCalendarResource> => data.map(parseTeacher));
  }
  createTeacher(teacher: User): Promise<any> {
    return responseData(
      axiosClient.post("/teacher/", teacher),
    );
  }
  updateTeacher(teacher: User): Promise<any> {
    return responseData(
      axiosClient.patch(`/teacher/${teacher.id}/`, teacher),
    );
  }
  deleteTeacher(studentId: number): Promise<any> {
    return responseData(
      axiosClient.delete(`/teacher/${studentId}/`),
    );
  }
  getTeacherEvents(teacherId: number, pagination: IPagination): Promise<any> {
    return responseData(
      axiosClient.get(`/teacher/${teacherId}/events/`, {
        params: pagination,
      }),
    ).then(({ data }) : ICalendarEvent[] => data);
  }

  /** Companies **/
  getAllCompanies(): Promise<any> {
    return responseData(
      axiosClient.get("/company/all/"),
    ).then(({ data }) : Array<ICompany> => data);
  }
  getCompanies(
    pagination?: IPagination,
    searchQuery?: string,
  ): Promise<any> {
    return responseData(
      axiosClient.get("/company/", {
        params: {
          ...pagination,
          q: searchQuery,
        },
      }),
    );
  }
  createCompany(company: Company): Promise<any> {
    return responseData(
      axiosClient.post("/company/", company),
    );
  }
  updateCompany(company: Company): Promise<any> {
    return responseData(
      axiosClient.patch(`/company/${company.id}/`, company),
    );
  }
  exportCompanyAttendancesCSV(company: Company): Promise<any> {
    return responseData(
      axiosClient.get(`/company/${company.id}/attendances/csv/`, {
        responseType: "blob",
      }),
    );
  }
}

function parseTeacher(teacher: ITeacher) {
  /** Parse ITeacher to the ITeacherCalendarResource **/
  return {
    ...teacher,
    resourceId: teacher.id,
    title: [teacher.first_name, teacher.last_name].join(" "),
  };
}
function parseStudentClassPurchase(purchase: any) {
  /** Parse ITeacher to the ITeacherCalendarResource **/
  return {
    ...purchase,
    paid_date: purchase.paid_date ? new Date(purchase.paid_date) : undefined,
    invoice_issued_date: purchase.invoice_issued_date ? new Date(purchase.invoice_issued_date) : undefined,
  };
}

export const userApi = new UserApi();
