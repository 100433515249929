<template>
  <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-sm-down">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <template v-if="isEdit">
            Edit
          </template>
          <template v-else>
            Add
          </template>
          Class
        </h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="close"
        />
      </div>

      <div class="modal-body">
        <form v-if="schoolClass">
          <div class="container main-container g-0">
            <div class="row">
              <div class="col">
                <label for="class" class="col-form-label"> Teacher </label>
                <SelectInput
                  id="class"
                  v-model="schoolClass.teacher"
                  :options="getChoices('teacher')"
                  :class="{ 'is-invalid': inputErrors['teacher'] }"
                  placeholder="Select a teacher..."
                />
                <div class="invalid-feedback">
                  {{ inputErrors["teacher"] }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label class="col-form-label"> Weekday </label>
                <SelectInput
                  id="class"
                  v-model="schoolClass.weekday"
                  :options="getChoices('weekday')"
                  :disable-search="true"
                  :class="{ 'is-invalid': inputErrors['weekday'] }"
                  placeholder="Select a weekday..."
                />
              </div>
              <div class="col">
                <div class="d-flex class_event_time_range">
                  <div>
                    <label for="first_name" class="col-form-label"> Time </label>
                    <Timepicker v-model="schoolClass.time" :min="8" :max="21" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-sm-4">
                <label for="class" class="col-form-label"> Class Language </label>
                <SelectInput
                  id="class"
                  v-model="schoolClass.class_language"
                  :options="getChoices('class_language')"
                  :disable-search="true"
                  :class="{ 'is-invalid': inputErrors['class_language'] }"
                  placeholder="Select a class language..."
                />
                <div class="invalid-feedback">
                  {{ inputErrors["class_language"] }}
                </div>
              </div>
              <div class="col-12 col-sm-4">
                <label for="level" class="col-form-label"> Language Level </label>
                <SelectInput
                  id="level"
                  v-model="schoolClass.level"
                  :class="{ 'is-invalid': inputErrors['level'] }"
                  :options="getChoices('level')"
                />
                <div class="invalid-feedback">
                  {{ inputErrors["level"] }}
                </div>
              </div>
              <div class="col-12 col-sm-4">
                <label for="level" class="col-form-label"> Company </label>
                <SelectInput
                  id="company"
                  v-model="schoolClass.company"
                  :options="getChoices('company')"
                  :disable-search="true"
                  :class="{ 'is-invalid': inputErrors['company'] }"
                />
                <div class="invalid-feedback">
                  {{ inputErrors["company"] }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label for="class" class="col-form-label"> Class Location </label>
                <SelectInput
                  id="class"
                  v-model="schoolClass.class_location"
                  :options="getChoices('class_location')"
                  :disable-search="true"
                  :class="{ 'is-invalid': inputErrors['class_location'] }"
                  placeholder="Select a class location..."
                />
                <div class="invalid-feedback">
                  {{ inputErrors["class_location"] }}
                </div>
              </div>
              <div class="col">
                <label for="class" class="col-form-label"> Class Type </label>
                <SelectInput
                  id="class"
                  v-model="schoolClass.class_type"
                  :options="getChoices('class_type')"
                  :disable-search="true"
                  :class="{ 'is-invalid': inputErrors['class_type'] }"
                  placeholder="Select a class type..."
                />
                <div class="invalid-feedback">
                  {{ inputErrors["class_type"] }}
                </div>
              </div>
              <div class="col checkbox-center">
                <label class="col-form-label" for="is_class_active"> Is Class Active </label>
                <div class="form-check">
                  <input
                    id="is_class_active"
                    v-model="schoolClass.is_active"
                    class="form-check-input d-block"
                    type="checkbox"
                  >
                </div>
              </div>
              <div class="col checkbox-center">
                <label class="col-form-label" for="is_class_active"> Is Individual </label>
                <div class="form-check">
                  <input
                    id="is_class_active"
                    v-model="schoolClass.is_individual"
                    class="form-check-input d-block"
                    type="checkbox"
                  >
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col">
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <h6 class="mb-0">
                    Class Students
                  </h6>
                  <button type="button" class="btn btn-outline-primary" @click="openSelectStudentsModal">
                    <i class="bi bi-plus-lg" /> Select Students
                  </button>
                </div>
                <div v-if="schoolClass.students.length" class="attendance_table_wrapper">
                  <SchoolClassStudentsTable
                    :student-ids="schoolClass.students"
                    @selected-students-changed="selectedStudentsChanged"
                  />
                </div>
                <div v-else class="text-light-emphasis text-center py-2">
                  Currently there are no students in this class.<br>
                  Click the <strong>Select Students</strong> button to add them.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <div v-if="inputErrors['non_field_errors']" class="w-100">
          <div
            class="alert alert-danger p-1 mt-3 mb-0"
            role="alert"
          >
            {{ inputErrors["non_field_errors"] }}
          </div>
        </div>
        <div v-if="showOnlyOneStudentWarning" class="w-100">
          <div class="alert alert-warning p-1 mb-0" role="alert">
            This class is a group class but it only has one student. <br>
            Check if it is supposed to be an individual class.
          </div>
        </div>
        <div v-if="showManyStudentWarning" class="w-100">
          <div class="alert alert-warning p-1 mb-0" role="alert">
            This class is an individual class but it has more than one student. <br>
            Check if it is supposed to be a group class (uncheck Is Individual).
          </div>
        </div>
        <div v-if="isEdit && userStore.isCurrentTeacherAdmin" class="me-auto">
          <button type="button" class="btn btn-danger" @click="confirmDeleteClassEvent">
            Delete
          </button>
        </div>
        <button type="button" class="btn btn-secondary" @click="close">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="loading"
          @click="save"
        >
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import SelectInput from "@/components/ui/input/SelectInput.vue";
import { IInputErrors } from "@/types/input";
import { useUserStore } from "@/stores/user.store";
import { useCalendarStore } from "@/stores/calendar.store";
import Timepicker from "@/components/ui/timepicker/Timepicker.vue";
import timeMixin from "@/mixins/time.mixin";
import { useToastStore } from "@/stores/toast.store";
import { useModalStore } from "@/stores/modal.store";
import { ref } from "vue";
import SchoolClass from "@/types/models/school_class";
import SchoolClassStudentsTable from "@/components/modals/calendar/school_class/SchoolClassStudentsTable.vue";
import { useStudentStore } from "@/stores/student.store";
import setsMixin from "@/mixins/sets.mixin";


export default defineComponent({
  name: "SchoolClassModal",
  components: { SchoolClassStudentsTable, SelectInput, Timepicker },
  mixins: [mappingsMixin, timeMixin, setsMixin],
  props: {
    schoolClassProp: {
      type: Object as PropType<SchoolClass>,
      default: () => {
        return null;
      },
    },
  },
  emits: ["close"],
  setup() {
    const userStore = useUserStore();
    const studentStore = useStudentStore();
    const calendarStore = useCalendarStore();
    const toastStore = useToastStore();
    const modalStore = useModalStore();
    const inputErrors = ref({} as IInputErrors);
    const schoolClass = ref();
    const loading = ref(false);

    return {
      userStore,
      studentStore,
      calendarStore,
      toastStore,
      modalStore,
      schoolClass,
      inputErrors,
      loading,
    };
  },
  computed: {
    isEdit() {
      return this.schoolClass?.id != null;
    },
    schoolClassTeacher() {
      return this.schoolClass ? this.getMapping("teacher", this.schoolClass?.teacher) : undefined;
    },
    showOnlyOneStudentWarning(): boolean {
      // Show warning if only 1 student and class is not individual class.
      return !this.schoolClass?.is_individual && this.schoolClass?.students.length === 1;
    },
    showManyStudentWarning(): boolean {
      return !!this.schoolClass?.is_individual && this.schoolClass?.students.length > 1;
    },
  },
  mounted() {
    this.schoolClass = new SchoolClass({
      id: this.schoolClassProp?.id,
      teacher: this.schoolClassProp?.teacher,
      title: this.schoolClassProp?.title,
      class_location: this.schoolClassProp?.class_location ?? this.getDefaultChoice("class_location"),
      class_type: this.schoolClassProp?.class_type ?? this.getDefaultChoice("class_type"),
      class_language: this.schoolClassProp?.class_language ?? this.getDefaultChoice("class_language"),
      level: this.schoolClassProp?.level ?? this.getDefaultChoice("level"),
      weekday: this.schoolClassProp?.weekday,
      time: this.schoolClassProp?.time ?? "12:00",
      created: this.schoolClassProp?.created,
      is_active: this.schoolClassProp?.is_active,
      is_individual: this.schoolClassProp?.is_individual,
      company: this.schoolClassProp?.company,
      students: this.schoolClassProp?.students,
      student_names: this.schoolClassProp?.student_names,
    });
  },
  created() {
    this.studentStore.resetState();
    this.studentStore.resetAttendanceStudents();
    if (this.schoolClassProp?.students) {
      this.studentStore.getAttendanceStudents(this.schoolClassProp?.students);
    }
  },
  methods: {
    confirmDeleteClassEvent() {
      this.modalStore.openDeleteSchoolClassModal(this.schoolClass, this.deleteSchoolClass);
    },
    async save() {
      this.loading = true;
      try {
        await this.calendarStore.saveSchoolClass(this.schoolClass);
      } catch (error: any) {
        this.inputErrors = error;
        this.loading = false;
        return;
      }
      this.loading = false;

      this.close();
    },
    async deleteSchoolClass() {
      this.loading = true;
      try {
        await this.calendarStore.deleteSchoolClass(this.schoolClass);
      } catch (error: any) {
        this.inputErrors = error;
        this.loading = false;
        return;
      }
      this.loading = false;

      this.close();
    },
    close() {
      this.$emit("close");
    },
    selectedStudentsChanged(newSelectedStudents: number[]) {
      const oldStudents = new Set(this.schoolClass.students);
      const newStudents = new Set(newSelectedStudents);
      this.schoolClass.students = newSelectedStudents;
      this.studentStore.attendanceStudents = this.studentStore.attendanceStudents.filter(student => newSelectedStudents.includes(student.id as number));

      console.log("selectedStudents changed: ", oldStudents, newSelectedStudents);
      if (this.setsAreEqual(oldStudents, newStudents) || this.isSetSubset(newStudents, oldStudents)) {
        // There is no need to refetch students if there was
        // no change or if some of the students were removed.
        return;
      }

      this.studentStore.resetState();
      this.studentStore.getAttendanceStudents(newSelectedStudents);
    },
    openSelectStudentsModal() {
      this.modalStore.openSelectStudentsSubModal(this.schoolClass.students, this.selectedStudentsChanged);
    },
  },
});
</script>
<style scoped lang="scss">
@include sm {
  .modal-content {
    max-height: 90vh;
  }
}
@include lg {
  .modal-dialog.modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-body {
  overflow: auto;
}
.class_event_time_range {
  gap: 12px;
  display: flex;
}
.attendance_table_wrapper {
  overflow: auto;
}
:deep(.start_date) {
  max-width: 100%;
}
</style>
