import { pickBy, isNil } from "lodash";
import { AxiosResponse } from "axios";

/**
 * Builds a query string array from congif object
 * @param obj - { string: boolean } object
 * @returns - comma-separated string with selected values
 */
export const buildQueryArray = (obj: Record<string, boolean>) => {
  return Object.entries(obj)
    .filter(([, value]) => !!value)
    .map(([key]) => key)
    .join(",");
};

/**
 * Removes nil attributes from the object
 * @param obj - { string: any } object
 * @returns - object without nil attributes
 */
export const cleanQuery = <T>(obj: T): T => {
  return pickBy(obj, (attr: any) => !isNil(attr));
};

export default interface IResponse {
    data: object;
};;;;;;;;;;;

export const responseData = <T>(req: Promise<AxiosResponse<T>>) => {
  return req
    .then(({ data }) => data)
    .catch(({ response }) => {
      // If status 400, extract and return the data and return the rejected promise.
      if (response.status === 400) {
        return Promise.reject(response.data);
      } else if (response.status === 500) {
        return Promise.reject({ non_field_errors: ["Oops, something went wrong"] });
      }

      // Return the original response
      return Promise.reject(response);
    });
};
