<template>
  <div class="container main-container g-0 personal_info">
    <div>This is the original data that the student registered with in our online registration form.</div>
    <FullScreenLoadingSpinner v-if="loading" />

    <div v-else class="row">
      <div class="col-12 col-sm-6">
        <label for="name" class="col-form-label">Name</label>
        <div>{{ studentRegistration.name ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="email" class="col-form-label">E-mail</label>
        <div>{{ studentRegistration.email ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="phone" class="col-form-label">Phone</label>
        <div>{{ studentRegistration.phone ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="birth_date" class="col-form-label">Birth Date</label>
        <div>{{ formatDate(studentRegistration.birth_date) ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="address" class="col-form-label">Address</label>
        <div>{{ studentRegistration.address ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="from_city_country" class="col-form-label">From City/Country</label>
        <div>{{ studentRegistration.from_city_country ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="current_level" class="col-form-label">Current Level</label>
        <div>{{ studentRegistration.current_level ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="weekly_hours" class="col-form-label">Weekly Hours</label>
        <div>{{ studentRegistration.weekly_hours ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="availability_time" class="col-form-label">Availability Time</label>
        <div>{{ studentRegistration.availability_time ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="availability_days" class="col-form-label">Availability Days</label>
        <div>{{ studentRegistration.availability_days ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="class_type" class="col-form-label">Class Type</label>
        <div>{{ studentRegistration.class_type ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="class_expectations" class="col-form-label">Class Expectations</label>
        <div>{{ studentRegistration.class_expectations ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="discovery_method" class="col-form-label">Discovery Method</label>
        <div>{{ studentRegistration.discovery_method ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="learn_reason" class="col-form-label">Reason for Learning</label>
        <div>{{ studentRegistration.learn_reason ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="learning_experience" class="col-form-label">Learning Experience</label>
        <div>{{ studentRegistration.learning_experience ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="course_interest" class="col-form-label">Course Interest</label>
        <div>{{ studentRegistration.course_interest ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="blog_subscription" class="col-form-label">Blog Subscription</label>
        <div>{{ studentRegistration.blog_subscription ?? '-' }}</div>
      </div>
      <div class="col-12 col-sm-6">
        <label for="created" class="col-form-label">Created Date</label>
        <div>{{ formatDate(studentRegistration.created) ?? '-' }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { api } from "@/api";
import { defineComponent, PropType, ref } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import useDateMixin from "@/mixins/date.mixin";
import IStudentRegistration from "@/types/student_registration";
import FullScreenLoadingSpinner from "@/components/ui/spinner/FullScreenLoadingSpinner.vue";

export default defineComponent({
  name: "StudentPersonalInfo",
  components: { FullScreenLoadingSpinner },
  mixins: [mappingsMixin],
  props: {
    studentRegistrationId: {
      type: Number,
      default: undefined,
    },
    studentRegistrationProp: {
      type: Object as PropType<IStudentRegistration>,
      default: () => {
        return {};
      },
    },
  },
  setup(props) {
    const { formatDate } = useDateMixin();
    const loading = ref(false);
    const studentRegistration = ref<IStudentRegistration>(props.studentRegistrationProp);
    return { formatDate, loading, studentRegistration };
  },
  created() {
    if (!this.studentRegistration?.id) {
      this.getStudentRegistrationData();
    }
  },
  methods: {
    async getStudentRegistrationData() {
      if (!this.studentRegistrationId) return;
      this.loading = true;

      return api.userApi
        .getStudentRegistration(this.studentRegistrationId)
        .then((data) => {
          this.studentRegistration = data;
        })
        .catch((e) => console.error(e))
        .finally(() => (this.loading = false));
    },
  },
});
</script>

<style scoped lang="scss">
.personal_info {
  height: 100%;
  display: flex;
  flex-direction: column;

  .main-container {
    height: 100%;
    overflow: hidden auto;
    flex-grow: 1;
  }
  .row {
    row-gap: 0.6em;
  }
  label {
    font-weight: bold;
  }
}
</style>
