<template>
  <div class="modal-dialog modal-dialog-centered modal-lg modal-fullscreen-sm-down">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Select Recouped Class Event
          <span class="text-secondary">{{ studentFullName }}</span>
        </h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="close"
        />
      </div>

      <div class="modal-body" :class="{'center_content': !attendancesToRecoupExist || loadingAttendances}">
        <LoadingSpinner v-if="loadingAttendances" />
        <div v-else-if="attendancesToRecoupExist" class="container main-container g-0">
          <div class="row">
            <div class="col">
              <SelectRecoupedAttendanceTable
                v-model="selectedRecoupedAttendance"
                v-model:selected-recouped-event="selectedRecoupedEvent"
                :attendances="attendancesToRecoup"
              />
            </div>
          </div>
        </div>
        <template v-else>
          There are no previous class attendances to recoup.
        </template>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          @click="close"
        >
          Cancel
        </button>
        <button
          v-if="attendancesToRecoupExist"
          type="button"
          class="btn btn-primary"
          @click="confirmSelection"
        >
          Confirm Selection
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { api } from "@/api";
import { defineComponent, PropType, ref } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import { useUserStore } from "@/stores/user.store";
import SelectRecoupedAttendanceTable from "@/components/modals/calendar/event/SelectRecoupedAttendanceTable.vue";
import { IStudentEventAttendanceExtended } from "@/types/student_class_purchase";
import { IFullStudentEventAttendance } from "@/types/student_event_attendance";
import LoadingSpinner from "@/components/ui/spinner/LoadingSpinner.vue";


export default defineComponent({
  name: "SelectRecoupedAttendanceSubModal",
  components: { LoadingSpinner, SelectRecoupedAttendanceTable },
  mixins: [mappingsMixin],
  props: {
    attendance: {
      type: Object as PropType<IFullStudentEventAttendance>,
      default: undefined,
    },
    selectedCallback: {
      type: Function,
      default: () => {
        return null;
      },
    },
  },
  emits: ["close"],
  setup(props) {
    const userStore = useUserStore();
    // Create a copy to prevent modifying the parent.
    const attendancesToRecoup = ref<IStudentEventAttendanceExtended[]>([]);
    const loadingAttendances = ref(false);
    const selectedRecoupedAttendance = ref<number | undefined>(props.attendance?.recouped_attendance);
    const selectedRecoupedEvent = ref<any | undefined>(props.attendance?.recouped_event);
    return {
      userStore,
      attendancesToRecoup,
      loadingAttendances,
      selectedRecoupedAttendance,
      selectedRecoupedEvent,
    };
  },
  computed: {
    attendancesToRecoupExist() {
      return this.attendancesToRecoup.length > 0;
    },
    studentFullName() {
      const student = this.attendance?.student;
      const fullName = [student?.first_name, student?.last_name].join(" ")
      if (fullName) {
        return `(${fullName})`;
      }

      return "";
    },
  },
  created() {
    if (!this.attendance?.student?.id) return;
    this.loadingAttendances = true;
    api.userApi.getStudentAttendancesToRecoup(this.attendance).then(
      data => this.attendancesToRecoup = data,
    ).catch(e => console.error(e))
      .finally(
        () => this.loadingAttendances = false,
      )
  },
  methods: {
    confirmSelection() {
      this.selectedCallback(this.attendance, this.selectedRecoupedAttendance, this.selectedRecoupedEvent);
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
});
</script>
<style scoped lang="scss">
.modal-content {
  min-height: 500px;
  max-height: 95%;
  margin: auto;
}
.modal-body.center_content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.no_attendances_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
@include sm {
  .modal-content {
    height: auto;
  }
  .modal-body {
    overflow-x: hidden;
  }
}
</style>
