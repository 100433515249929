import { format, Locale } from "date-fns";
import { enUS } from "date-fns/locale";
import { inject } from "vue";


export default () => {
  // Access the global locale from app.config.globalProperties
  const globalLocale: Locale = inject("$dateLocale", enUS);

  const formatDate = (isoDate?: string | Date, longFormat: boolean = false): string => {
    if (!isoDate) return "";
    const date = new Date(isoDate);
    const strFormat = longFormat ? "EEEE, d. LLL yyyy" : "d. LLL yyyy";
    return format(date, strFormat, { locale: globalLocale });
  };

  const formatDatetime = (isoDate?: string): string => {
    if (!isoDate) return "";
    const date = new Date(isoDate);
    return format(date, "d. LLL yyyy", { locale: globalLocale }) + " " + formatTime(isoDate);
  };

  const formatTime = (isoDate?: string): string => {
    // Example isoDate:
    // 2024-07-16T09:30:00Z
    if (!isoDate) return "";
    return isoDate.split("T")[1].substring(0, 5)
  };

  return { formatDate, formatDatetime, formatTime };
};
