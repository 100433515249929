<template>
  <form v-if="teacher" autocomplete="off">
    <div class="container main-container g-0 mt-2">
      <div class="row">
        <div class="col">
          <label for="first_name" class="col-form-label"> Name </label>
          <input
            id="first_name"
            v-model="teacher.first_name"
            type="text"
            class="form-control"
            :disabled="!canEdit"
            :class="{ 'is-invalid': inputErrors['first_name'] }"
          >
          <div class="invalid-feedback">
            {{ inputErrors["first_name"] }}
          </div>
        </div>
        <div class="col">
          <label for="last_name" class="col-form-label"> Surname </label>
          <input
            id="last_name"
            v-model="teacher.last_name"
            type="text"
            class="form-control"
            :disabled="!canEdit"
            :class="{ 'is-invalid': inputErrors['last_name'] }"
          >
          <div class="invalid-feedback">
            {{ inputErrors["last_name"] }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <label for="email" class="col-form-label">E-mail</label>
          <input
            id="email"
            v-model="teacher.email"
            type="email"
            class="form-control"
            :disabled="!canEdit"
            autocomplete="off"
            :class="{ 'is-invalid': inputErrors['email'] }"
          >
          <div class="invalid-feedback">
            {{ inputErrors["email"] }}
          </div>
        </div>

        <div class="col checkbox-center">
          <label class="col-form-label"> Is Active </label>
          <div class="form-check">
            <input
              v-model="teacher.is_active"
              class="form-check-input d-block"
              type="checkbox"
              :disabled="!canEdit"
            >
          </div>
        </div>

        <div
          v-if="userStore.isCurrentTeacherAdmin"
          class="col checkbox-center"
        >
          <label class="col-form-label"> Is Admin </label>
          <div class="form-check">
            <input
              v-model="teacher.is_staff"
              class="form-check-input d-block"
              type="checkbox"
              :disabled="!userStore.isCurrentTeacherAdmin"
            >
          </div>
        </div>
      </div>

      <div v-if="canEdit" class="row mt-4">
        <div class="col-12 mb-3">
          <strong>
            Change Password
          </strong>
        </div>
        <div class="col-12 col-sm-6">
          <label for="password1" class="col-form-label">Password</label>
          <input
            id="password1"
            v-model="teacher.password1"
            type="password"
            class="form-control"
            :disabled="!canEdit"
            autocomplete="new-password"
            :class="{ 'is-invalid': inputErrors['password1'] || inputErrors['password'] }"
          >
          <div class="invalid-feedback">
            {{ inputErrors['password1'] || inputErrors['password'] }}
          </div>
        </div>
      </div>
      <div v-if="canEdit" class="row">
        <div class="col-12 col-sm-6">
          <label for="password2" class="col-form-label">Repeat Password</label>
          <input
            id="password2"
            v-model="teacher.password2"
            type="password"
            class="form-control"
            :disabled="!canEdit"
            autocomplete="new-password"
            :class="{ 'is-invalid': inputErrors['password2'] || inputErrors['password'] }"
          >
          <div class="invalid-feedback">
            {{ inputErrors['password2'] || inputErrors['password'] }}
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { IInputErrors } from "@/types/input";
import { defineComponent, PropType } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import User from "@/types/models/user";
import { useUserStore } from "@/stores/user.store";


export default defineComponent({
  name: "TeacherPersonalInfo",
  mixins: [mappingsMixin],
  props: {
    modelValue: {
      type: Object as PropType<User>,
      default: () => {
        return {}
      },
    },
    inputErrors: {
      type: Object as PropType<IInputErrors>,
      default: () => {
        return {};
      },
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const userStore = useUserStore();
    return { userStore }
  },
  computed: {
    teacher: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
    canEdit() {
      // Is admin user or editing himself.
      return this.userStore.isCurrentTeacherAdmin || this.userStore.currentTeacher.id === this.teacher?.id;
    },
  },
})
</script>


<style scoped lang="scss"></style>
