import { axiosClient } from "@/api";
import { responseData } from "@/api/utils";

class MappingsApi {
  getMappings(): Promise<any> {
    return responseData(
      axiosClient.get("/mappings/"),
    );
  }
}

export const mappingsApi = new MappingsApi();
