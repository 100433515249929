<template>
  <div class="spinner">
    <div
      class="spinner-border text-secondary"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <div v-if="text" class="spinner__text">
      {{ text }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "LoadingSpinner",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
})
</script>

<style scoped lang="scss">
.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 0.75rem;
}
</style>
