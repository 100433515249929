<template>
  <i v-if="isTrue" class="bi bi-check-circle-fill text-success" />
  <i v-else class="bi bi-x-circle-fill text-danger" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BooleanCheckCircle",
  props: {
    isTrue: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
</style>
