<template>
  <div
    class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <template v-if="isEdit">
            Edit
          </template>
          <template v-else>
            Add
          </template>
          Our Language Hour Event
        </h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="close"
        />
      </div>

      <div class="modal-body">
        <form v-if="event">
          <div class="container main-container g-0">
            <div class="row">
              <div class="col-12 col-lg-6 mt-4 mt-lg-0">
                <label for="class" class="col-form-label"> Teacher </label>
                <SelectInput
                  id="class"
                  v-model="event.teacher"
                  :options="teacherChoices"
                  :class="{ 'is-invalid': inputErrors['teacher'] }"
                  placeholder="Select a teacher..."
                />
                <div class="invalid-feedback">
                  {{ inputErrors["teacher"] }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label class="col-form-label"> Date </label>
                <VueDatePicker
                  v-model="startDate"
                  input-class-name="form-control"
                  placeholder="Select date"
                  :format="formatDate"
                  :enable-time-picker="false"
                  :clearable="false"
                  :disabled-week-days="[6, 0]"
                  hide-input-icon
                  auto-apply
                  :teleport="true"
                />
              </div>
              <div class="col">
                <div class="d-flex class_event_time_range">
                  <div>
                    <label for="first_name" class="col-form-label"> Start </label>
                    <Timepicker v-model="startTime" :min="8" :max="21" />
                  </div>

                  <div>
                    <label for="first_name" class="col-form-label"> End </label>
                    <Timepicker v-model="endTime" :min="startDate" :max="21" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label for="notes" class="col-form-label">Notes</label>
                <textarea
                  id="notes"
                  v-model="event.notes"
                  class="form-control"
                  rows="2"
                />
              </div>
            </div>

            <template v-if="event && 'attendances' in event">
              <div class="row mt-2">
                <div class="col">
                  <div class="d-flex justify-content-between align-items-center mb-2">
                    <h6 class="mb-0">
                      Our Language Hour Student
                    </h6>
                    <button
                      type="button"
                      class="btn btn-outline-primary"
                      @click="openSelectStudentsModal"
                    >
                      <i class="bi bi-plus-lg" /> Select Student
                    </button>
                  </div>
                  <div v-if="event.attendances.length" class="attendance_table_wrapper">
                    <EventAttendanceTable
                      :event-start="event.start"
                      :attendances="event.attendances"
                      @attendances-changed="attendancesChanged"
                    />
                  </div>
                  <div v-else class="text-light-emphasis text-center py-2">
                    Select a student to attend this <strong>Our Language Hour</strong>.
                  </div>
                </div>
              </div>
            </template>
          </div>
        </form>

        <div v-if="inputErrors['non_field_errors'] || inputErrors['attendances']" class="alert alert-danger p-1 mb-0" role="alert">
          {{ inputErrors["non_field_errors"] || inputErrors['attendances'] }}
        </div>
      </div>
      <div class="modal-footer">
        <div v-if="isEdit" class="me-auto">
          <button type="button" class="btn btn-danger" @click="confirmDelete">
            Delete
          </button>
        </div>
        <button type="button" class="btn btn-secondary" @click="close">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" @click="saveEvent">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { api } from "@/api";
import { defineComponent } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import SelectInput from "@/components/ui/input/SelectInput.vue";
import { IInputErrors } from "@/types/input";
import { useUserStore } from "@/stores/user.store";
import { useCalendarStore } from "@/stores/calendar.store";
import Timepicker from "@/components/ui/timepicker/Timepicker.vue";
import timeMixin from "@/mixins/time.mixin";
import { useToastStore } from "@/stores/toast.store";
import { useModalStore } from "@/stores/modal.store";
import { watch, ref, onBeforeUnmount } from "vue";
import EventAttendanceTable from "@/components/modals/calendar/event/EventAttendanceTable.vue";
import IStudentEventAttendance from "@/types/student_event_attendance";
import { EventType } from "@/types/enums/event_type";
import CalendarClassEvent from "@/types/models/calendar_class_event";
import useDateMixin from "@/mixins/date.mixin";


export default defineComponent({
  name: "OurLanguageHourModal",
  components: { SelectInput, Timepicker, EventAttendanceTable },
  mixins: [mappingsMixin, timeMixin],
  props: {
    dateEventInfo: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  emits: ["close"],
  setup(props) {
    const userStore = useUserStore();
    const calendarStore = useCalendarStore();
    const toastStore = useToastStore();
    const modalStore = useModalStore();
    const { formatDate } = useDateMixin();
    const event = ref<CalendarClassEvent>(
      calendarStore.getEventFromFullCalendarEvent(props.dateEventInfo) as CalendarClassEvent,
    );
    updateOurLanguageHourEvent();

    const inputErrors = ref({} as IInputErrors);

    // Watch for changes in the dateEventInfo prop
    const stopWatch = watch(
      () => props.dateEventInfo,
      (newDateEventInfo) => {
        // React to changes here
        event.value = calendarStore.getEventFromFullCalendarEvent(newDateEventInfo) as CalendarClassEvent;
        updateOurLanguageHourEvent();
      },
    );

    onBeforeUnmount(() => {
      // Cleanup logic, e.g., stopping the watcher
      stopWatch();
    });

    function updateOurLanguageHourEvent() {
      event.value.event_type = EventType.OUR_LANGUAGE_HOUR;
    }

    return { userStore, calendarStore, toastStore, modalStore, formatDate, event, inputErrors };
  },
  computed: {
    isEdit() {
      return this.event?.id != null;
    },
    teacherChoices() {
      return this.getChoices("teacher").filter(teacher => teacher.is_active);
    },
    startDate: {
      get() {
        return new Date(this.event.start);
      },
      set(newDate: Date) {
        const startDate = new Date(this.event.start);

        startDate.setFullYear(newDate.getFullYear());
        startDate.setMonth(newDate.getMonth());
        startDate.setDate(newDate.getDate());
        this.event.start = startDate.toISOString();

        // Also update the end date to match the start date.
        const endDate = new Date(this.event.end);
        endDate.setFullYear(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        this.event.end = endDate.toISOString();
      },
    },
    startTime: {
      get() {
        return new Date(this.event.start);
      },
      set(newTime: string) {
        const startDate = new Date(this.event.start);
        const [hours, minutes] = newTime.split(":");
        this.event.start = this.setTimeOnDate(startDate, hours, minutes).toISOString();
      },
    },
    endTime: {
      get() {
        return new Date(this.event.end);
      },
      set(newTime: string) {
        const endDate = new Date(this.event.end);
        const [hours, minutes] = newTime.split(":");
        this.event.end = this.setTimeOnDate(endDate, hours, minutes).toISOString();
      },
    },
    attendanceStudentIds(): number[] {
      if ("attendances" in this.event) {
        return this.event?.attendances.map(attendance => attendance.student);
      }
      return []
    },
  },
  methods: {
    async saveEvent() {
      this.inputErrors = this.event.validate();

      if (Object.keys(this.inputErrors).length > 0) {
        console.warn("Save event validation failed: ", this.inputErrors)
        return;
      }

      try {
        await this.calendarStore.saveEvent(this.event);
      } catch (error: any) {
        this.inputErrors = error;
        return;
      }

      // Re-fetch events to update events calendar.
      this.dateEventInfo.view.calendar.refetchEvents();
      this.close();
    },
    confirmDelete() {
      this.modalStore.openDeleteEventModal(this.event, this.deleteEvent);
    },
    async deleteEvent() {
      // Delete the existing class event.
      try {
        await api.calendarApi.deleteEvent(this.event?.id);
      } catch (error: any) {
        Object.keys(error).forEach(function (key) {
          error[key] = error[key].join("\n");
        });
        this.inputErrors = error;
        return;
      }

      // Remove the event from the calendar.
      this.dateEventInfo.event.remove();

      // Show success message.
      this.toastStore.addToast("Our Language Hour event was deleted successfully.");
      this.close();
    },
    attendancesChanged(newAttendances: IStudentEventAttendance[]) {
      if ("attendances" in this.event) {
        this.event.attendances = newAttendances;
      }
    },
    selectedStudentsChanged(newStudentIds: number[]) {
      if (!("attendances" in this.event)) return [];

      // Filter existing attendances and discard those that were not selected.
      const existingAttendances = this.event.attendances.filter(
        attendance => newStudentIds.includes(attendance.student),
      );

      // Create new attendances for the students that were newly selected.
      const newAttendances = newStudentIds
        .filter((studentId) =>
          !existingAttendances.some((attendance) => attendance.student === studentId),
        )
        .map((studentId) => ({
          id: undefined,
          student: studentId,
          attended: false,
          no_show: false,
        } as IStudentEventAttendance));
      console.log("merged Attendances");
      console.log(existingAttendances.concat(newAttendances));
      this.attendancesChanged(existingAttendances.concat(newAttendances))
    },
    openSelectStudentsModal() {
      this.modalStore.openSelectStudentsSubModal(
        this.attendanceStudentIds,
        this.selectedStudentsChanged,
        true,
      );
    },
    close() {
      this.$emit("close");
    },
  },
});
</script>
<style scoped lang="scss">
.class_event_time_range {
  gap: 12px;
  display: flex;
}
.modal-body {
  overflow: auto;
}
@include md {
  .modal-dialog.modal-xl {
    --bs-modal-width: 1040px;
  }
}
@include xl {
  .modal-dialog.modal-xl {
    --bs-modal-width: 1200px;
  }
}
.attendance_table_wrapper {
  overflow: auto;
}

:deep(.start_date) {
  max-width: 100%;
}
</style>
