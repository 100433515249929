import axios from "axios";
import * as api from "./modules";
export * as api from "./modules";

// AXIOS
export const axiosClient = axios.create({
  baseURL: (import.meta.env.VITE_API_URL || "http://localhost:8000") + "/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.request.use((config) => {
  const tokens = JSON.parse(<string>localStorage.getItem("tokens"));

  config.headers.Authorization = `Bearer ${tokens?.access}`;
  return config;
});

axiosClient.interceptors.response.use(
  (res) => {
    return Promise.resolve(res);
  },
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      api.authApi.destroyToken();
    }
    // status might be undefined
    if (!status) {
      api.authApi.refreshToken();
    }
    return Promise.reject(error);
  },
);
