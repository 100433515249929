<template>
  <div v-if="student" class="attendances">
    <div class="table_wrapper">
      <table class="app_table table table-striped table-hover table-sm">
        <thead class="table-light">
          <tr>
            <th class="th_index" scope="col">
              #
            </th>
            <th class="th_date" scope="col">
              Title
            </th>
            <th class="th_number_of_classes" scope="col">
              Location
            </th>
            <th class="th_number_of_classes" scope="col">
              Created
            </th>
            <th class="th_checkbox" scope="col">
              Students
            </th>
            <th class="th_checkbox" scope="col">
              Individual
            </th>
            <th class="th_checkbox" scope="col">
              Is Active
            </th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <tr>
            <td colspan="10">
              <LoadingSpinner class="my-2" text="Loading Classes..." />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <template v-for="(schoolClass, index) in classes" :key="index">
            <tr>
              <th scope="row">
                {{ classes.length - index }}
              </th>
              <td class="td_title">
                {{ schoolClass.title }}
              </td>
              <td class="td_class">
                {{ getMapping("class_location", student.class_location, "-") }}
              </td>
              <td class="td_date">
                {{ formatDate(schoolClass.created) }}
              </td>
              <td class="td_checkbox">
                <VTooltip
                  :disabled="schoolClass.students?.length <= 0"
                >
                  <template #popper>
                    <div v-for="student in schoolClass.student_names">
                      {{ student }}
                    </div>
                  </template>
                  <span
                    class="attendance_badge badge rounded-pill"
                    :class="schoolClass.students.length > 0 ? 'bg-secondary' : 'bg-danger'"
                  >
                    <i class="bi bi-people-fill" /> {{ schoolClass.students.length }}
                  </span>
                </VTooltip>
              </td>
              <td class="td_checkbox">
                <BooleanCheckCircle :is-true="schoolClass.is_individual" />
              </td>
              <td class="td_checkbox">
                <BooleanCheckCircle :is-true="schoolClass.is_active" />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { api } from "@/api";
import Student from "@/types/models/student";
import { defineComponent, PropType, ref } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import useDateMixin from "@/mixins/date.mixin";
import LoadingSpinner from "@/components/ui/spinner/LoadingSpinner.vue";
import BooleanCheckCircle from "@/components/ui/BooleanCheckCircle.vue";
import ISchoolClass from "@/types/school_class";

export default defineComponent({
  name: "StudentActiveClasses",
  components: { BooleanCheckCircle, LoadingSpinner },
  mixins: [mappingsMixin],
  props: {
    modelValue: {
      type: Object as PropType<Student>,
      default: () => {
        return {};
      },
    },
  },
  setup() {
    const loading = ref(false);
    const classes = ref<ISchoolClass[]>([]);

    const { formatDate, formatTime } = useDateMixin();
    return { formatDate, formatTime, loading, classes };
  },
  computed: {
    student(): Student {
      return this.modelValue;
    },
  },
  created() {
    if (this.student.id) {
      this.loading = true;
      api.userApi
        .getStudentClasses(this.student.id)
        .then((classes) => {
          console.log(classes);
          this.classes = classes;
        })
        .catch((e) => console.error(e))
        .finally(() => (this.loading = false));
    }
  },
});
</script>

<style scoped lang="scss">
.attendances {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .table_wrapper {
    overflow: auto;
    margin-top: 1rem;
    @include sm {
      margin-top: 0;
    }
    @include md {
      height: 600px;
      max-height: 600px;
    }
  }
  table {
    border-collapse: collapse;
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
  .th_index {
    width: 1rem;
  }
  .td_date {
    min-width: 7rem;
    width: 7rem;
    vertical-align: middle;
  }
  .td_class {
    min-width: 12rem;
    width: 12rem;
  }
  .th_checkbox,
  .td_checkbox {
    width: 3rem !important;
    min-width: 3rem !important;
  }
  td {
    vertical-align: middle;
  }
}
</style>
