<template>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Delete{{ computedObjectName }}
        </h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="close"
        />
      </div>

      <div class="modal-body">
        <div class="container g-0">
          <div class="row">
            <div class="col">
              Are you sure you want to delete <strong>{{ title }}</strong>{{ computedObjectName.toLowerCase() }}?
            </div>
          </div>
          <template v-if="relatedObjects?.length">
            <div class="row">
              <div class="col">
                <i class="bi bi-exclamation-triangle-fill text-warning fs-3" />
                This will also delete <strong>{{ relatedObjects?.length }}</strong> {{ relatedObjectsName }}.
              </div>
            </div>
            <div class="row">
              <div class="col related_objects_list">
                <ol>
                  <li v-for="(obj, index) in relatedObjects" :key="index">
                    {{ obj }}
                  </li>
                </ol>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-danger" @click="confirmDelete">
          Delete
        </button>
        <button type="button" class="btn btn-secondary" @click="close">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ConfirmDeleteModal",
  props: {
    objectName: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    relatedObjectsName: {
      type: String,
      default: "",
    },
    relatedObjects: {
      type: Array,
      default() {
        return [];
      },
    },
    confirmDeleteCallback: {
      type: Function,
      default: () => {
        return null;
      },
    },
  },
  emits: ["close", "deleteConfirmed"],
  computed: {
    computedObjectName() {
      if (this.objectName) {
        return " " + this.objectName;
      }

      return "";
    },
  },
  methods: {
    confirmDelete() {
      this.close();
      this.confirmDeleteCallback();
    },
    close() {
      this.$emit("close");
    },
  },
});
</script>
<style scoped lang="scss">
.modal-dialog {
  max-width: 600px;
}
.related_objects_list {
  max-height: 250px;
  overflow-y: scroll;
}
</style>
