<template>
  <div class="timepicker">
    <select id="hours" v-model="hours" class="form-select">
      <option v-for="option in hourOptions" :key="option.id" :value="option.id">
        {{ option.name }}
      </option>
    </select>
    <select id="minutes" v-model="minutes" class="form-select">
      <option v-for="option in minuteOptions" :key="option.id" :value="option.id">
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import ISelectInputChoice from "@/types/input";
import { defineComponent } from "vue";
import timeMixin from "@/mixins/time.mixin";

export default defineComponent({
  name: "Timepicker",
  mixins: [timeMixin],
  props: {
    modelValue: {
      type: [String, Date],
      default: "",
    },
    min: {
      type: [Number, String, Date],
      default: 0,
    },
    max: {
      type: Number,
      default: 23,
    },
    minuteInterval: {
      type: Number,
      default: 5,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    minHour() {
      if (typeof this.min === "number") {
        return this.min;
      } else if (typeof this.min === "string") {
        return parseInt(this.min.split(":")[0]);
      }

      return this.min.getUTCHours();
    },
    hourOptions() {
      return generateTimeChoices(this.minHour , this.max ?? 23, 1);
    },
    minuteOptions() {
      return generateTimeChoices(0 , 59, this.minuteInterval);
    },
    hours: {
      get() {
        if (this.modelValue instanceof Date) {
          // Parse and format the hour as "HH"
          return this.modelValue.getUTCHours().toString().padStart(2, "0");
        }
        // It is a string, parse hours.
        return this.modelValue.split(":")[0] || "";

      },
      set(hours: string) {
        this.$emit("update:modelValue", `${hours}:${this.minutes}`);
      },
    },
    minutes: {
      get() {
        if (this.modelValue instanceof Date) {
          // Parse and format the minutes as "MM"
          return this.modelValue.getUTCMinutes().toString().padStart(2, "0");
        }
        // It is a string, parse minutes.
        return this.modelValue.split(":")[1] || "";
      },
      set(minutes: string) {
        // if (this.modelValue instanceof Date) {
        //   this.$emit("update:modelValue", this.setTimeOnDate(this.modelValue, this.hours, minutes));
        // }
        this.$emit("update:modelValue", `${this.hours}:${minutes}`);
      },
    },
  },
});

function generateTimeChoices(from: number, to: number, interval: number): ISelectInputChoice[] {
  const timeList: ISelectInputChoice[] = [];

  for (let t = from; t <= to; t += interval) {
    const formatted = t.toString().padStart(2, "0");
    timeList.push({
      id: formatted,
      name: formatted,
    });
  }

  return timeList;
}
</script>

<style scoped lang="scss">
.timepicker {
  display: flex;
  gap: 2px;
}
.form-select {
  width: 65px;
  padding-right: 2em;
}
</style>
