import { defineStore } from "pinia";
import { api } from "@/api";
import { useMappingsStore } from "@/stores/mappings.store";
import Student from "@/types/models/student";
import { useToastStore } from "@/stores/toast.store";
import IStudentClassPurchase from "@/types/student_class_purchase";
import IPagination from "@/types/pagination";
import IStudent from "@/types/student";


export const useStudentStore = defineStore({
  id: "student",
  state: () => ({
    searchQuery: "",
    filter: undefined as Record<string, any> | undefined,
    sort: [] as string[],
    hideInitialClassPassed: undefined as boolean | undefined,
    selectedStudentId: undefined as number | undefined,
    pagination: {
      page: 1, // Current page number
      page_size: 15, // Number of items per page
      count: 0, // Total number of items across all pages
      total_pages: 1, // Total number of pages, calculated from count
    } as IPagination,
    refreshStudentRegistrationsCounter: 0,
    // Students array used when filtering & searching.
    students: [] as IStudent[],
    // Attendance students used in attendance tables.
    attendanceStudents: [] as IStudent[],
    loading: {
      students: false,
      attendanceStudents: false,
      studentClassPurchases: false,
      deleteStudent: false,
      studentsToBeLinked: false,
    },
  }),
  getters: {
    mappingsStore() {
      return useMappingsStore();
    },
    toastStore() {
      return useToastStore();
    },
  },
  actions: {
    resetState() {
      console.log("resetState");
      this.resetPagination();
      this.students = [];
      this.filter = undefined;
      this.sort = [];
      this.searchQuery = "";
      this.hideInitialClassPassed = undefined;
      this.selectedStudentId = undefined;
    },
    resetAttendanceStudents() {
      this.attendanceStudents = [];
    },
    resetPagination() {
      this.pagination = {
        page: 1,
        page_size: 15,
        count: 0,
        total_pages: 1,
      };
    },
    setMappingStudents(students: any) {
      this.mappingsStore.clearKey("student");
      this.mappingsStore.addMapping(students, "student");
      this.mappingsStore.addChoices(students, "student","title");
    },
    async getStudents(resetStudents = false): Promise<void> {
      this.loading.students = true;
      if (resetStudents) {
        this.students = [];
      }

      return api.userApi
        .getStudents(
          this.pagination,
          this.searchQuery,
          this.filter,
          this.sort,
          this.hideInitialClassPassed,
          this.selectedStudentId,
        )
        .then((students) => {
          this.pagination = {
            count: students.count,
            page: students.page,
            total_pages: students.total_pages,
            page_size: students.page_size,
          }
          this.students = students.data;
          this.setMappingStudents(students.data);
        })
        .catch((e) => console.error(e))
        .finally(() => (this.loading.students = false));
    },
    async getAttendanceStudents(studentIds?: number[]): Promise<void> {
      if (!studentIds?.length) {
        this.mappingsStore.clearKey("student");
        this.attendanceStudents = [];
        return;
      }
      this.loading.attendanceStudents = true;

      return api.userApi
        .getStudentsByIds(studentIds)
        .then((students) => {
          console.log("students by ids: ", students);
          this.attendanceStudents = students;
          this.setMappingStudents(students);
        })
        .catch((e) => console.error(e))
        .finally(() => (this.loading.attendanceStudents = false));
    },
    async getStudentsTobeLinked(studentId?: number) : Promise<void> {
      /** Students to be linked from a registration student **/
      this.loading.studentsToBeLinked = true;
      return api.userApi.getStudentsToBeLinked(studentId)
        .then(students => {
          this.mappingsStore.addChoices(students, "studentToLink","title_long");
        })
        .catch((error: Error) => console.error(error))
        .finally(() => this.loading.studentsToBeLinked = false);
    },
    async getStudentClassPurchases(studentId: number): Promise<IStudentClassPurchase[]> {
      this.loading.studentClassPurchases = true;
      return api.userApi
        .getStudentClassPurchases(studentId)
        .catch((e) => console.error(e))
        .finally(() => (this.loading.studentClassPurchases = false));
    },
    async deleteStudent(studentId: number): Promise<void> {
      this.loading.deleteStudent = true;
      return api.userApi
        .deleteStudent(studentId)
        .catch((e) => console.error(e))
        .finally(() => (this.loading.deleteStudent = false));
    },
    async saveStudent(student: Student) {
      const inputErrors = student.validate();

      if (Object.keys(inputErrors).length > 0) return Promise.reject(inputErrors);

      let response;
      try {
        if (student.id == null) {
          // Create new class event.
          response = await api.userApi.createStudent(student);
          this.toastStore.addToast("A new student was added successfully.");
        } else {
          // Update the existing student.
          response = await api.userApi.updateStudent(student);
          this.toastStore.addToast("Student was updated successfully.");
        }
      } catch (error: any) {
        console.error(error);
        Object.keys(error).forEach(function(key) {
          error[key] = error[key].join("\n");
        });
        return Promise.reject(error);
      }

      return response?.data;
    },
  },
});
