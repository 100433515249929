<template>
  <div v-if="teacher" class="events">
    <div v-if="loadingEvents">
      <LoadingSpinner class="my-2" text="Loading Class Purchases..." />
    </div>
    <div v-else-if="events.length" class="table_wrapper">
      <table class="app_table table table-striped table-hover table-sm">
        <thead class="table-light">
          <tr>
            <th class="th_index" scope="col">
              #
            </th>
            <th class="th_date" scope="col">
              Date
            </th>
            <th class="th_number_of_classes" scope="col">
              Time
            </th>
            <th class="th_number_of_classes" scope="col">
              Class
            </th>
            <th class="th_attended th_checkbox" scope="col">
              Students
            </th>
            <th class="th_attended th_checkbox" scope="col">
              ATT
            </th>
            <th class="th_no_show th_checkbox" scope="col">
              NS
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(event, index) in events" :key="index">
            <tr>
              <th scope="row">
                {{ events.length - index }}
              </th>
              <td class="td_date">
                {{ formatDate(event.start) }}
              </td>
              <td class="td_time">
                {{ formatTime(event.start) }} -
                {{ formatTime(event.end) }}
              </td>
              <td class="td_class">
                <div class="event_wrapper">
                  <div class="event_type" :class="`bg_${event.event_type}`" />{{ getEventTitle(event) }}
                </div>
              </td>
              <td class="td_attended td_checkbox">
                {{ event.attendances?.length }}
              </td>
              <td class="td_attended td_checkbox">
                {{ event.attendances?.filter(a => a.attended)?.length }}
              </td>
              <td class="td_no_show td_checkbox">
                {{ event.attendances?.filter(a => a.no_show)?.length }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-else>
      Teacher has no previous events.
    </div>
    <Pagination
      v-if="events.length && !loadingEvents"
      :pagination="pagination"
      @page-changed="pageChanged"
    />
  </div>
</template>

<script lang="ts">
import { api } from "@/api";
import { defineComponent, PropType, ref } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import useDateMixin from "@/mixins/date.mixin";
import { useUserStore } from "@/stores/user.store";
import LoadingSpinner from "@/components/ui/spinner/LoadingSpinner.vue";
import User from "@/types/models/user";
import IClassCalendarEvent from "@/types/calendar_class_event";
import Pagination from "@/components/ui/pagination/Pagination.vue";
import IPagination from "@/types/pagination";

export default defineComponent({
  name: "TeacherEvents",
  components: { LoadingSpinner, Pagination },
  mixins: [mappingsMixin],
  props: {
    modelValue: {
      type: Object as PropType<User>,
      default: () => {
        return {};
      },
    },
  },
  setup() {
    const userStore = useUserStore();
    const loadingEvents = ref(false);
    const events = ref<IClassCalendarEvent[]>([]);

    // Pagination
    const pagination = ref<IPagination>({
      page: 1, // Current page number
      page_size: 15, // Number of items per page
      count: 0, // Total number of items across all pages
      total_pages: 1, // Total number of pages, calculated from count
    });

    const { formatDate, formatTime } = useDateMixin();
    return { userStore, formatDate, formatTime, loadingEvents, events, pagination };
  },
  computed: {
    teacher(): User {
      return this.modelValue;
    },
  },
  created() {
    this.loadEvents();
  },
  methods: {
    getEventTitle(event: IClassCalendarEvent) {
      if (event.title) return event.title;

      return this.getMapping("event_type", event.event_type);
    },
    pageChanged(page: number) {
      this.pagination.page = page;
      this.loadEvents();
    },
    loadEvents() {
      if (!this.teacher.id) return;
      this.loadingEvents = true;
      api.userApi
        .getTeacherEvents(this.teacher.id, this.pagination)
        .then((events) => {
          console.log(events);
          this.pagination.count = events.count;
          this.pagination.total_pages = events.total_pages;
          this.events = events.data;
        })
        .catch((e) => console.error(e))
        .finally(() => (this.loadingEvents = false));
    },
  },
});
</script>

<style scoped lang="scss">
.events {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .table_wrapper {
    flex-grow: 1;
    overflow: auto;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    width: 100%;

    @include sm {
      margin-top: 0;
    }
  }
  table {
    border-collapse: collapse;
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  .th_index {
    width: 1rem;
  }

  .td_time,
  .td_date {
    min-width: 7rem;
    width: 7rem;
    vertical-align: middle;
  }
  .td_no_show,
  .td_attended,
  .th_attended,
  .th_no_show {
    width: 3rem;
    min-width: 3rem;
  }
  .td_recouped_date {
    width: 12.5rem;
    min-width: 12.5rem;
  }
  .td_class_purchase {
    width: 7.5rem;
  }

  td {
    vertical-align: middle;
  }

  .td_class {
    min-width: 14rem;
    width: 14rem;
    .event_wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .event_type {
        margin-right: 0.5rem;
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 0.25rem;
      }
    }
  }
}
</style>
