import { axiosClient } from "@/api";

class AuthApi {
  private failedAttempts: number;
  constructor() {
    this.failedAttempts = 0;
  }
  login(email: string, password: string): Promise<any> {
    return axiosClient.post("/auth/login/", { email, password });
  }
  refreshToken() {
    const tokens = JSON.parse(<string>localStorage.getItem("tokens"));

    return new Promise((resolve, reject) => {
      axiosClient
        .post("/auth/login-refresh/", {
          refresh_token: tokens?.refresh,
        })
        .then((response) => {
          this.failedAttempts = 0; // Reset the counter on a successful attempt
          this.saveToken(response.data);
          return resolve(response.data.refresh);
        })
        .catch((error) => {
          this.failedAttempts += 1; // Increment the counter on a failed attempt
          console.error("FAIL refresh token", error);

          if (this.failedAttempts >= 3) {
            console.log("Exceeded maximum refresh attempts. Logging out.");
            this.destroyToken();
            // Optionally, redirect to the login page
            window.location.href = "/login"; // Update with the correct path
          }

          return reject(error);
        });
    });
  }
  saveToken(tokens: string) {
    localStorage.setItem("tokens", tokens);
  }
  destroyToken() {
    localStorage.removeItem("tokens");
  }
}

export const authApi = new AuthApi();
