<template>
  <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-sm-down">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Student Registration
          <span class="text-secondary">{{ studentRegistration.name }}</span>
        </h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="close"
        />
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-sm-6 mb-3">
            <label for="student" class="col-form-label">Linked Student</label>
            <div class="input-group">
              <LoadingSpinner
                v-if="studentStore.loading.studentsToBeLinked"
                class="d-flex flex-row linked_student_spinner"
                text="Loading Linked Student"
              />
              <SelectInput
                v-else
                id="class"
                v-model="linkedStudent"
                :options="studentToLinkChoices"
                :class="{ 'is-invalid': inputErrors['school_class'] }"
                placeholder="Link to an existing student..."
              />
              <div class="invalid-feedback">
                {{ inputErrors["school_class"] }}
              </div>
            </div>
          </div>
        </div>

        <StudentRegistrationData :student-registration-prop="studentRegistration" />
      </div>
      <div class="modal-footer">
        <div
          v-if="inputErrors['non_field_errors']"
          class="alert alert-danger p-1 mb-0 w-100"
          role="alert"
        >
          {{ inputErrors["non_field_errors"] || inputErrors["attendances"] }}
        </div>
        <div class="me-auto">
          <button
            v-tooltip="{content: createStudentDisabledText, disabled: isCreateStudentDisabled}"
            type="button"
            class="btn btn-success"
            :disabled="!!studentRegistration.student_created_from_registration || !!linkedStudent"
            @click="createStudent()"
          >
            Create a New Student From This Registration
          </button>
        </div>
        <button
          v-if="userStore.isCurrentTeacherAdmin"
          type="button"
          class="btn btn-danger"
          @click="deleteStudentRegistration"
        >
          Delete
        </button>
        <button type="button" class="btn btn-secondary" @click="close">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" @click="save">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { api } from "@/api";
import { defineComponent, PropType, ref } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import ISelectInputChoice, { IInputErrors } from "@/types/input";
import { useStudentStore } from "@/stores/student.store";
import { useToastStore } from "@/stores/toast.store";
import { useModalStore } from "@/stores/modal.store";
import IStudentRegistration from "@/types/student_registration";
import StudentRegistrationData from "@/components/modals/student/tabs/StudentRegistrationData.vue";
import SelectInput from "@/components/ui/input/SelectInput.vue";
import LoadingSpinner from "@/components/ui/spinner/LoadingSpinner.vue";
import { useUserStore } from "@/stores/user.store";

export default defineComponent({
  name: "StudentRegistrationModal",
  components: { LoadingSpinner, SelectInput, StudentRegistrationData },
  mixins: [mappingsMixin],
  props: {
    studentRegistration: {
      type: Object as PropType<IStudentRegistration>,
      default: () => {
        return {};
      },
    },
  },
  emits: ["close"],
  setup() {
    const userStore = useUserStore();
    const studentStore = useStudentStore();
    const toastStore = useToastStore();
    const modalStore = useModalStore();
    const inputErrors = ref({} as IInputErrors);
    const linkedStudent = ref<number | undefined>(undefined);

    return { userStore, studentStore, modalStore, toastStore, inputErrors, linkedStudent };
  },
  computed: {
    studentToLinkChoices(): ISelectInputChoice[] {
      return this.getChoices("studentToLink") as ISelectInputChoice[]
    },
    createStudentDisabledText() {
      if (this.studentRegistration.student_created_from_registration) {
        return "A student was already created from this registration.";
      }
      if (this.studentRegistration.student) {
        return "A student is already linked to this registration form data."
      }
    },
    isCreateStudentDisabled() {
      return !!this.createStudentDisabledText
    },
  },
  created() {
    this.studentStore.getStudentsTobeLinked(this.studentRegistration.student);
  },
  mounted() {
    this.linkedStudent = this.studentRegistration?.student;
  },
  methods: {
    async save() {
      if (!this.studentRegistration) return;

      try {
        await api.userApi.updateStudentRegistration(this.studentRegistration.id, this.linkedStudent);
      } catch (error: any) {
        console.error(error);
        Object.keys(error).forEach(function(key) {
          error[key] = error[key].join("\n");
        });
        this.inputErrors = error;
        return;
      }

      // Re-fetch students to update students table.
      this.studentStore.refreshStudentRegistrationsCounter += 1;
      this.close();
    },
    async createStudent() {
      if (this.studentRegistration?.student_created_from_registration) {
        this.toastStore.errorToast(
          "Student was already created from this registration. You cannot create it twice.",
        )
        return;
      }

      try {
        await api.userApi.createStudentFromRegistration(this.studentRegistration.id);
        this.toastStore.addToast("Student was updated successfully.");
        // Re-fetch students to update students table.
        this.studentStore.getStudents();
        this.studentStore.refreshStudentRegistrationsCounter += 1;
        this.close();
      } catch (error: any) {
        console.error(error);
        Object.keys(error).forEach(function(key) {
          error[key] = error[key].join("\n");
        });
        this.inputErrors = error;
      }
    },
    async deleteStudentRegistration() {
      try {
        await api.userApi.deleteStudentRegistration(this.studentRegistration.id);
        this.toastStore.addToast("Student registration was deleted successfully.");
        // Re-fetch students to update students table.
        this.studentStore.refreshStudentRegistrationsCounter += 1;
        this.close();
      } catch (error: any) {
        console.error(error);
        Object.keys(error).forEach(function(key) {
          error[key] = error[key].join("\n");
        });
        this.inputErrors = error;
      }
    },
    close() {
      this.$emit("close");
    },
  },
});
</script>
<style scoped lang="scss">
.modal-body {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.modal-content {
  min-height: 80vh;
}
.linked_student_spinner :deep(.spinner-border) {
  width: 1rem;
  height: 1rem;
}
@include sm {
  .modal-content {
    max-height: 90vh;
  }
  .modal-dialog.modal-xl {
    --bs-modal-width: 100%;
  }
}
@include lg {
  .modal-dialog.modal-xl {
    --bs-modal-width: 1140px;
  }
}
</style>
