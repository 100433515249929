import { defineStore } from "pinia";
import IToast from "@/types/ui/toast";


export const useToastStore = defineStore({
  id: "toast",
  state: () => ({
    toasts: [] as IToast[],
  }),

  actions: {
    addToast(message: string, level?: string, duration?: number): void {
      // Check if a toast with the same message already exists
      const existingToast = this.toasts.find((toast) => toast.message === message);

      if (existingToast) {
        // If found, close the existing toast
        this.closeToast(existingToast.id);
      }

      const id = Date.now();
      this.toasts.push({ id, message, level });

      setTimeout(() => {
        this.closeToast(id);
      }, 1000 * (duration ?? 4));
    },
    successToast(message: string, duration?: number) {
      this.addToast(message, "success", duration);
    },
    warningToast(message: string, duration?: number) {
      this.addToast(message, "warning", duration);
    },
    errorToast(message: string, duration?: number) {
      this.addToast(message, "error", duration);
    },
    closeToast(id: number): void {
      this.toasts = this.toasts.filter(
        (toast) => toast.id !== id,
      );
    },
  },
});
