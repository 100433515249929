<template>
  <nav>
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: pagination.page === 1 }">
        <a
          class="page-link"
          href="#"
          aria-label="Previous"
          @click.prevent="changePage(1)"
        >
          <i class="bi bi-chevron-double-left" />
        </a>
      </li>
      <li class="page-item ms-2" :class="{ disabled: pagination.page === 1 }">
        <a
          class="page-link"
          href="#"
          aria-label="Previous"
          @click.prevent="changePage(pagination.page - 1)"
        >
          <i class="bi bi-chevron-left" />
        </a>
      </li>
      <li>
        <div class="dots" :class="{'dots__active': showLeftDots}">
          ...
        </div>
      </li>

      <!-- Dynamically generate page numbers based on total_pages -->
      <li
        v-for="pageNum in pagesArray"
        :key="pageNum"
        :class="{ 'active': pagination.page === pageNum }"
        class="page-item"
      >
        <a class="page-link" href="#" @click.prevent="changePage(pageNum)">
          {{ pageNum }}
        </a>
      </li>
      <li>
        <div class="dots" :class="{'dots__active': showRightDots}">
          ...
        </div>
      </li>

      <li class="page-item me-2" :class="{ disabled: pagination.page === pagination.total_pages }">
        <a
          class="page-link"
          href="#"
          aria-label="Next"
          @click.prevent="changePage(pagination.page + 1)"
        >
          <i class="bi bi-chevron-right" />
        </a>
      </li>
      <li class="page-item" :class="{ disabled: pagination.page === pagination.total_pages }">
        <a
          class="page-link"
          href="#"
          aria-label="Next"
          @click.prevent="changePage(pagination.total_pages)"
        >
          <i class="bi bi-chevron-double-right" />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import IPagination from "@/types/pagination";

export default defineComponent({
  name: "Pagination",
  props: {
    pagination: {
      type: Object as PropType<IPagination>,
      required: true,
    },
  },
  emits: ["pageChanged"],
  computed: {
    // Generates an array of page numbers for rendering pagination buttons
    maximumPage(): number {
      if (this.pagination.count === 0 || !this.pagination?.count) {
        return 0;
      }
      return Math.ceil(this.pagination.count / this.pagination.page_size);
    },
    pagesArray(): number[] {
      // Determines how many items are on the left and right of the center element.
      const width = 2;
      if (!width) return [];

      let _ar = [];
      const centerPage = this.pagination.page;

      let leftCorrection = 0;
      let rightCorrection = 0;
      for (let i = 1; i <= width * 2 + 1; i++) {
        const pn = i + centerPage - (width + 1);
        if (pn >= 1 && pn <= this.maximumPage) {
          _ar.push(i + centerPage - (width + 1));
        } else {
          if (pn < 1) {
            leftCorrection = leftCorrection + 1;
          }
          if (pn > this.maximumPage) {
            rightCorrection = rightCorrection + 1;
          }
        }
      }

      const lastElement = _ar[_ar.length - 1];
      for (let j = 1; j <= leftCorrection; j++) {
        _ar.push(lastElement + j);
      }

      const firstElement = _ar[0];
      for (let k = 1; k <= rightCorrection; k++) {
        _ar.unshift(firstElement - k);
      }
      // trim out of bounds elements
      _ar = _ar.filter((el) => el >= 1 && el <= this.maximumPage);

      return _ar;
    },
    showLeftDots(): boolean {
      return !this.pagesArray.includes(1);
    },
    showRightDots(): boolean {
      return !this.pagesArray.includes(this.maximumPage);
    },
  },
  methods: {
    changePage(page?: number) {
      if (!page || this.pagination.page == page) return;
      this.$emit("pageChanged", page);
    },
  },
})
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 0.6rem;
}
.dots {
  width: 2rem;
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  height: 100%;
  letter-spacing: 0.1rem;
  margin: 0 0.25rem;

  &__active {
    visibility: visible;
  }
}
</style>
