export enum EventType {
  CLASS = "class",
  OUR_LANGUAGE_HOUR = "our_language_hour",
  NOT_AVAILABLE = "not_available",
  ADMIN_WORK = "admin_work",
  EXCEPTION = "exception",
  UNDEFINED = "",
}
export const BACKGROUND_EVENT_TYPES = [
  EventType.NOT_AVAILABLE,
  EventType.ADMIN_WORK,
  EventType.EXCEPTION,
]
