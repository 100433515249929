import { IInputErrors } from "@/types/input";
import ICalendarEvent from "@/types/calendar_event";
import { EventType } from "@/types/enums/event_type";

export default class CalendarEvent implements ICalendarEvent {
  id ;
  teacher;
  title = "";
  start;
  end;
  notes ;
  event_type ;

  constructor({
    id = undefined,
    teacher = undefined,
    title = "",
    start = "",
    end = "",
    notes = "",
    event_type = EventType.UNDEFINED,
  }: Partial<ICalendarEvent> = {}) {
    this.id = id;
    this.teacher = teacher;
    this.title = title;
    this.start = start;
    this.end = end;
    this.notes = notes;
    this.event_type = event_type;
  }

  validate() {
    const errors = {} as IInputErrors;
    if (!this.teacher) errors["teacher"] = "Teacher should not be empty."
    if (!this.start) errors["start"] = "Start date should not be empty."
    if (!this.end) errors["end"] = "End date should not be empty."
    return errors;
  }

  validate_teacher() {
    if (!this.teacher) return "Teacher should not be empty."
    return "";
  }
}
