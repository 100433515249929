import IUser from "@/types/user";
import { IInputErrors } from "@/types/input";

export default class User implements IUser {
  id;
  first_name;
  last_name;
  email;
  is_active;
  is_staff;
  created;
  password1;
  password2;

  constructor({
    id = undefined,
    first_name = "",
    last_name = "",
    email = "",
    created = "",
    is_active = true,
    is_staff = false,
    password1 = "",
    password2 = "",
  }: Partial<IUser> = {}) {
    this.id = id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.created = created;
    this.is_active = is_active;
    this.is_staff = is_staff;
    this.password1 = password1;
    this.password2 = password2;
  }

  validate() {
    const errors = {} as IInputErrors;
    if (!this.first_name) errors["first_name"] = "Name should not be empty.";
    if (!this.last_name) errors["last_name"] = "Surname should not be empty.";
    // if (!this.email) errors["email"] = "E-mail should not be empty.";
    return errors;
  }
}
