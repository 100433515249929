<template>
  <div class="row">
    <div class="col">
      <input
        id="search_query"
        v-model="searchQuery"
        class="form-control mb-2"
        placeholder="Search..."
      >
    </div>
  </div>
  <div class="students_table_wrapper">

    <table class="app_table table table-striped table-hover table-sm">
      <thead class="table-light">
        <tr>
          <th scope="col" class="th_checkbox is_active" />
          <th scope="col">
            Class Name
          </th>
          <th scope="col">
            Date
          </th>
          <th scope="col">
            Time
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="attendance in filteredAttendances"
          :key="attendance.id"
          @click="toggleSelected(attendance.id)"
        >
          <td class="td_checkbox is_active">
            <input
              v-model="selectedAttendance"
              :value="attendance.id"
              class="form-check-input d-block"
              type="radio"
            >
          </td>
          <td>{{ attendance.event.title }}</td>
          <td>{{ formatDate(attendance.event.start, true) }}</td>
          <td>{{ formatTime(attendance.event.start) }} - {{ formatTime(attendance.event.end) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import { IStudentEventAttendanceExtended } from "@/types/student_class_purchase";
import useDateMixin from "@/mixins/date.mixin";
import ICalendarEvent from "@/types/calendar_event";

export default defineComponent({
  name: "SelectRecoupedAttendanceTable",
  mixins: [mappingsMixin],
  props: {
    attendances: {
      type: Array as () => IStudentEventAttendanceExtended[],
      default: () => [],
    },
    modelValue: {
      type: Number,
      default: undefined,
    },
    selectedRecoupedEvent: {
      type: Object as PropType<ICalendarEvent>,
      default: undefined,
    },
  },
  emits: ["update:modelValue", "update:selectedRecoupedEvent"],
  setup() {
    const { formatDate, formatTime } = useDateMixin();
    return { formatDate, formatTime }
  },
  data() {
    return {
      searchQuery: "",
    }
  },
  computed: {
    selectedAttendance: {
      get() {
        // Getter just returns the value of the prop.
        return this.modelValue;
      },
      set(attendanceId: number) {
        // Setter emits an event to update the value in the parent component
        this.$emit("update:modelValue", attendanceId);
        this.$emit("update:selectedRecoupedEvent", this.attendances.find(att => att.id === attendanceId)?.event);
      },
    },
    filteredAttendances(): IStudentEventAttendanceExtended[] {
      // Match regex case-insensitive.
      const regex = new RegExp(this.searchQuery, "i");

      // Filter students by the search query.
      return this.attendances
        .filter(attendance => regex.test(attendance.event.title))
        .sort(this.studentSort)
    },
  },
  methods: {
    toggleSelected(attendanceId?: number) {
      if (this.selectedAttendance === attendanceId) {
        // Unselect the attendance radio selection.
        this.selectedAttendance = undefined;
      } else {
        this.selectedAttendance = attendanceId;
      }
    },
    studentSort(a: IStudentEventAttendanceExtended, b: IStudentEventAttendanceExtended) {
      /** Sort first by the title (descending)
       */
      return a.event.title.localeCompare(b.event.title)
    },
  },
})
</script>

<style scoped lang="scss">
.students_table_wrapper {
  overflow: auto;
  @include md {
    max-height:  600px;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
tr:hover {
  cursor: pointer;
}
.th_checkbox,
.td_checkbox {
  width: 120px;

  &.is_active {
    width: 50px;

    .form-check-input {
      width: 1.2em;
      height: 1.2em;
    }
  }
  &.remove_item {
    width: 50px;
    font-size: 1.1rem;
  }
}
</style>
